import MapLayers from '@/libs/MapLayers'

export default function (mapLayers: MapLayers) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function (app: any): void {
    app.config.globalProperties.$MapLayers = mapLayers
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $MapLayers: MapLayers
  }
}
