import { BvToast, BvToastOptions } from 'bootstrap-vue/src/components/toast'
import { ToastIgnore } from '@/libs/auth/types'
import { VNode } from 'vue'

export default class DodonaToast {
  private toastModal: BvToast | undefined


  constructor(private ignoreMessages: string[] = [], private ignoreTitles: string[] = []) {
  }

  toast(message: string, options?: BvToastOptions): void {
    if (this.hasMessage(message) || (options && options.title && this.hasTitle(options.title))) {
      return
    }

    if (this.toastModal !== undefined) {
      this.toastModal.toast(message, options)
    }
  }

  hasMessage(message: string): boolean {
    return this.ignoreMessages.includes(message.toLowerCase())
  }

  hasTitle(title: string | VNode | VNode[]): boolean {
    if (typeof title === 'string') {
      return this.ignoreTitles.includes(title.toLowerCase())
    }
    return false
  }

  setIgnoreMessages(toastIgnore: ToastIgnore[], toast: BvToast) {
    this.toastModal = toast
    this.ignoreTitles = []
    this.ignoreMessages = []
    toastIgnore
      .filter(toast => !!toast.toast_ignore_text.trim())
      .forEach(toast => {
        const text = toast.toast_ignore_text.trim().toLowerCase()
        if (toast.message_type === 'title') {
          this.ignoreTitles.push(text)
        } else {
          this.ignoreMessages.push(text)
        }
      })
  }
}
