import { UserRole, AppRole } from '@/auth/roles'
import { PlanningLevel } from '@/types/workspace'

export class RoleManager {
  private userRoles: UserRole[] = []

  constructor(initialRoles: UserRole[] = []) {
    this.userRoles = initialRoles
  }

  hasRole(role: UserRole | undefined): boolean {
    return !!role && this.userRoles.includes(role)
  }

  hasAllRoles(roles: UserRole[]): boolean {
    if (roles.length === 0) {
      return true
    }

    return roles.every(role => this.hasRole(role))
  }

  setRoles(roles: UserRole[]): void {
    this.userRoles = roles
  }

  availablePlanningLevels(): PlanningLevel[] {
    const planningLevels: PlanningLevel[] = []
    const rolesToLevels: [AppRole, PlanningLevel][] = [
      [AppRole.planningLevelLocal, PlanningLevel.local],
      [AppRole.planningLevelCountry, PlanningLevel.country],
      [AppRole.planningLevelStreetFleet, PlanningLevel.fleet],
    ]

    rolesToLevels.forEach(([role, level]) => {
      if (this.hasRole(role)) {
        planningLevels.push(level)
      }
    })

    return planningLevels
  }
}
