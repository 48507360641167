<template>
  <router-view
    v-slot="{ Component }"
  >
    <component
      :is="Component"
      v-if="Component"
    />

    <div v-else>
      <img
        id="loader-img"
        src="@/assets/brand/dodonaanalytics/logo.png"
        alt="logo"
      >
      <div id="app-loader" />
    </div>
  </router-view>
</template>
