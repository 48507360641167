import {EventMeta, EventType} from '@/libs/ActivityLogger/types'
import {isObject} from 'lodash'
import GlobalUtils from '@/utils/global.utils'
import {MapType, RegionCode, SupportedRegion} from '@/types/app'
import MapLayers from '@/libs/MapLayers'
import {Router} from 'vue-router'
import {WorkspaceStore} from '@/stores/workspace.store'
import {getWorkspaceEventInfo} from '@/utils/kibana.utils'
import {UserInterfaceStore} from '@/stores/user-interface.store'

type EventPayload = Record<string | number, unknown> | unknown

export default class ActivityLogger {
  /**
   * Bumped when breaking changes are introduced to the event schema, this usually means that an existing event's name
   * or payload structure was changed
   * @private
   */
  private readonly version = 0

  /**
   * When the current event version became active, dd/MM/yyyy
   * @private
   */
  private readonly versionStartDate = '01/08/2024'

  private sessionId?: string

  constructor(
    private mapLayers: MapLayers,
    private router: Router,
    private ws: WorkspaceStore,
    private uis: UserInterfaceStore,
    private username: string = '',
    private organization: string = '',
    private userId = '',
    private region: SupportedRegion = RegionCode.UK,
  ) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
        .then(registration => {
          console.info('Service Worker registered:', registration)
          registration.addEventListener('message', evt => {
            console.log('Service worker message', evt)
          })
        })
        .catch(error => {
          console.info('Service Worker registration failed:', error)
        })
    }
  }

  startSession(): void {
    // If there is an active session, end it first
    if (this.sessionId) {
      this.endSession()
    }

    this.sessionId = crypto.randomUUID()
    this.info('session-start')
  }

  endSession(): void {
    if (!this.sessionId) {
      return
    }

    this.info('session-end')
  }
  
  debug(event: string, payload?: EventPayload): void {
    this.sendMessage('debug', event, payload)
  }
  
  info(event: EventType, payload?: EventPayload): void {
    this.sendMessage('info', event, payload)
  }
  
  warn(event: string, payload?: EventPayload): void {
    this.sendMessage('warn', event, payload)
  }
  
  error(event: string, payload?: EventPayload): void {
    this.sendMessage('error', event, payload).then(() => {
      // Dispatch HotJar event, window.hj will only exist on production
      if ((window as any).hj) {
        (window as any).hj('event', 'error')
        console.debug('Dispatched HotJar error event')
      }
    })
  }
  
  private async sendMessage(logLevel: 'warn' | 'info' | 'debug' | 'error', event: string, payload?: EventPayload) {
    const kibanaPayload = isObject(payload) ? JSON.stringify(payload) : payload
    const workerPayload = {
      sessionId: this.sessionId,
      version: this.version,
      versionStartDate: this.versionStartDate,
      logLevel: logLevel,
      event: event,
      payload: kibanaPayload,
      username: this.username,
      userId: this.userId,
      organization: this.organization,
      timestamp: this.getCurrentDateTime(),
      region: this.region,
      env: GlobalUtils.env(),
      meta: this.getEventMeta(),
    }

    try {
      if (GlobalUtils.isProduction() || GlobalUtils.isStaging()) {
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.ready
          registration.active?.postMessage(workerPayload)
        }
      }

      if (GlobalUtils.isDev() || GlobalUtils.isTesting()|| GlobalUtils.isStaging()) {
        console[logLevel]('[EVENT]', event, payload, '\n', workerPayload)
      }
    } catch (error) {
      console.error('Issue sending message to worker', error)
    }
  }

  private getCurrentDateTime() {
    const now = new Date()
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString()
  }

  private getEventMeta(): EventMeta {
    const map = this.mapLayers.getMap()
    const route = this.router.currentRoute.value.fullPath

    let view: EventMeta['view'] | undefined = undefined
    if (route.includes('workspace')) {
      view = 'workspace'
    } else if (route.includes('portfolio')) {
      view = 'portfolio'
    } else if (route.includes('explorer')) {
      view = 'area-explorer'
    }

    const workspace = getWorkspaceEventInfo(this.ws.workspace)

    return {
      workspace,
      view,
      zoom: map?.getZoom(),
      lat: map?.getCenter()?.lat(),
      lng: map?.getCenter()?.lng(),
      locationName: this.uis.mapLocationName,
      mapType: map?.getMapTypeId() as MapType,
    }
  }
  
  setUser(username: string, userId: string, organization: string) {
    this.username = username
    this.userId = userId
    this.organization = organization
  }

  setRegion(region: SupportedRegion) {
    this.region = region
  }
}
