import {Locale} from '@/plugins/i18n'
import GlobalUtils from '@/utils/global.utils'

export interface RegionConfig {
  locale: Locale
  measurementSystem: 'imperial' | 'metric'
}

/**
 * Which region the app instance is deployed in
 */
const regionConfigs: Record<string, RegionConfig> = {
  UK: {
    locale: 'en-gb',
    measurementSystem: 'metric',
  },
  US: {
    locale: 'en-us',
    measurementSystem: 'imperial',
  },

  DE: {
    locale: 'en-us',
    measurementSystem: 'metric',
  },
}

export function regionConfig(): RegionConfig {
  return regionConfigs[GlobalUtils.getAppRegion()]
}
