<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16"
      cy="17"
      r="15"
      :fill="color"
      stroke="white"
      stroke-width="3"
    />
    <text
      x="50%"
      y="55%"
      font-size="14px"
      font-weight="500"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      font-family="Roboto, sans-serif"
    >{{ label }}</text>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
    },
    label: {
      type: String,
    },
  },
})
</script>