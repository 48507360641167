import { DodonaMapEvents } from '@/utils/eventBus.utils'

export interface MapData {
  map: google.maps.Map | null
}

export interface RegionHighlightEvent {
  localAuthorityPolygon: google.maps.Data
  localAuthorityShadowPolygon: google.maps.Data
}

export enum GoogleMapTypes {
  roadmap = 'roadmap',
  satellite = 'satellite',
  // same as satelite but includes street numbers on map
  hybrid = 'hybrid',
}

export class MapLayerIds {
  public static moveEventCatcherPolygon = 'moveEventCatcherPolygon' as DodonaMapEvents
  public static workspaceRegionShadow = 'workspaceRegionShadow' as DodonaMapEvents
  public static workspaceGlobalShadow = 'workspaceGlobalShadow' as DodonaMapEvents
  public static workspaceSiteMoveId = 'workspaceSiteMoveId' as DodonaMapEvents
  public static infillLsoa = 'infill-lsoa0' as DodonaMapEvents
}
