import { Option } from '@/components/DSelect.vue'
import { SiteUtils } from '@/models/SiteSelection/Site.model'
import { OriginalLabel, SiteStatus } from '@/types/workspace'
import { keyBy } from 'lodash'
import {defineStore} from 'pinia'


interface State {
  allSiteStatuses: SiteStatus[]
}
export const useSiteStatusStore = defineStore('siteStatusStore', {
  state: (): State => ({
    allSiteStatuses: [],
  }),
  getters: {
    // the statuses are order by configuration layout and only the active ones are shown
    siteStatuses(): SiteStatus[] {
      const purpose = this.getFullActiveStatuses.filter(s => s.is_default)
      const others = this.getFullActiveStatuses.filter(s => !s.is_default && s.protected).toSorted(SiteUtils.sortByUUID)
      const dynamic = this.getFullActiveStatuses.filter(s => s.position !== 0).toSorted((a, b) => a.position - b.position) 

      return [
        ...purpose,
        ...dynamic,
        ...others,
      ]
    },
    getFullStatuses(): SiteStatus[] {
      return [...new Set<SiteStatus>(this.allSiteStatuses)]
    },
    getFullActiveStatuses(): SiteStatus[] {
      return [
        ...new Set<SiteStatus>(this.allSiteStatuses.filter((s) => !s.archived)),
      ]
    },
    getFullArchivedStatuses(): SiteStatus[] {
      return [
        ...new Set<SiteStatus>(this.allSiteStatuses.filter((s) => s.archived)),
      ]
    },
    getApprovedStatus(): SiteStatus | null {
      return this.allSiteStatuses
        .find((s) => s.original_label === 'APPROVED' as OriginalLabel) || null
    },
    getActiveStatuses(): string[] {
      return this.siteStatuses
        .map(({ status }) => status)
    },
    getActiveStatusOptions(): Option[] {
      return this.getActiveStatuses.map((status: string) => {
        return {
          value: status,
          label: status,
        }
      })
    },
    getArchivedStatusOptions(): (id: string) => Option[] {
      return (statusId: string) => {
        return this.getArchivedStatuses(statusId)
          .map((status: string) => {
            return {
              value: status,
              label: status,
              disabled: true,
            }
          })
      }
    },
    getArchivedStatuses() {
      return (siteId: string) => {
        return [...new Set<string>(this.allSiteStatuses
          .filter((s) => s.archived && s.id === siteId)
          .map(({status}) => status)),
        ]
      }
    },
    getActiveStatusDictionary(): { [key: string]: SiteStatus } {
      const statuses: SiteStatus[] = this.getFullActiveStatuses || []
      return keyBy(statuses, 'id')
    },
    getArchivedStatusDictionary(): { [key: string]: SiteStatus } {
      const statuses: SiteStatus[] = this.getFullArchivedStatuses || []
      return keyBy(statuses, 'id')
    },
    getStatuses(): string[] {
      return [...new Set<string>(this.allSiteStatuses.map(({ status }) => status))]
    },
  },
  actions: {
    setSiteStatuses(statuses: SiteStatus[]) {
      this.allSiteStatuses = statuses
    },
    
    async fetchSiteStatuses() {
      this.allSiteStatuses = await this.api.getStatuses()
    },
  },
})