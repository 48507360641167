import ActivityLogger from '@/libs/ActivityLogger'


export default function (activityLogger: ActivityLogger) {
  return function (app: any): void {
    app.config.globalProperties.$ActivityLogger = activityLogger
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ActivityLogger: ActivityLogger
  }
}

