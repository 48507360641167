import {localeFormatDistance, pretty} from '@/libs/texts'
import {numberFormatting} from '@/libs/numbers'


export type SiteMetricType = string | number | null

export interface SiteMetricsForSite {
  [siteId: string]: Record<string, SiteMetricType>
}

export interface SiteMetricCategory {
  index: number
  data_points: string[]
}

export interface SiteMetrics {
  point_data: SiteMetricsForSite
  categories: Record<string, SiteMetricCategory>
}

function formatSiteMetricsKey(key: string): string {
  const pattern = /(\d+)_meters/g
  const localeKey = key.replace(pattern, (match, p1) => {
    return localeFormatDistance(Number(p1))
  })
  return pretty(localeKey, true)
}

const distanceFormattingKeys = ['nearest_motorway_junction', 'nearest_A_road']

export function readableSiteMetrics(metrics: SiteMetrics): SiteMetrics {
  const transformed: SiteMetrics = { ...metrics }

  // Transform point_data
  for (const [siteId, siteData] of Object.entries(transformed.point_data)) {
    const newSiteData: { [key: string]: SiteMetricType } = {}
    for (const [key, value] of Object.entries(siteData)) {
      let newValue: SiteMetricType = value
      if (typeof value === 'string') {
        newValue = pretty(value)
      } else if (typeof value === 'number') {
        if (distanceFormattingKeys.includes(key)) {
          newValue = localeFormatDistance(value)
        } else {
          newValue = numberFormatting(value)
        }
      }
      newSiteData[formatSiteMetricsKey(key)] = newValue
    }
    transformed.point_data[siteId] = newSiteData
  }

  // Transform categories
  for (const category of Object.values(transformed.categories)) {
    category.data_points = category.data_points.map(formatSiteMetricsKey)
  }

  return transformed
}
