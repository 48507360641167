import { keyBy } from 'lodash'

export enum ChecklistActionType {
  delete = 'delete',
  none= 'none',
}

export enum ChecklistStatusState {
  drag = 'drag',
}

export interface Checklist {
  id: string | number
  text: string
  user: string
  created: string
  action: ChecklistActionType
  status: ChecklistStatusState
  position: number
}

export interface SiteChecklistOutput {
  id?: string,
  created_at: string,
  created_by_name: string,
  checklist_id: string,
  label: string,
}

export interface SiteChecklistPerSite {
  checklistsPerSite: {
    [siteId: string]: {
      [ChecklistLabel: string]: boolean
    }
  }
}

export interface SiteChecklist {
  id: string
  text: string
  created: string | null
  user: string | null
  selected: boolean 
}

export interface ChecklistOutput {
  id: string
  label: string
  created_by_email: string
  position: number
  created_at: string
}


export class ChecklistModelMapper {
  public static addChecklistItem(position: number): Omit<Checklist, 'id'> {
    return {
      position,
      text: '',
      user: '',
      created: (new Date).toISOString(),
      action: ChecklistActionType.none,
      status: ChecklistStatusState.drag,
    }
  }

  public static toChecklist(item: ChecklistOutput): Checklist {
    return {
      id: item.id,
      text: item.label,
      user: item.created_by_email,
      created: item.created_at,
      action: ChecklistActionType.delete,
      status: ChecklistStatusState.drag,
      position: item.position,
    }
  }

  public static toSitesChecklist(checklist: Checklist[], selectedItems: SiteChecklistOutput[]): SiteChecklist[] {
    const dict = keyBy(selectedItems, 'checklist_id')

    const collection = checklist.map(item => {
      const user = dict[item.id]

      return {
        id: item.id,
        text: item.text,
        created: user?.created_at || null,
        user: user?.created_by_name || null,
        selected: !!user,
        position: item.position,
      }
    })

    return collection.toSorted((item1, item2) => item1.position - item2.position) as SiteChecklist[]
  }

  public static toBackendChecklist(checklist: Checklist): Pick<ChecklistOutput, 'id' | 'label' | 'position'> {
    return {
      id: checklist.id as string,
      label: checklist.text,
      position: checklist.position,
    }
  }
}
