import DonutChart from '@/components/Map/overlays/DonutChart.vue'
import { createApp } from 'vue'
import MarkerGraphContainer from './MarkerGraphContainer.vue'

export class VectorUtils {

  public static generateDonutSvg(arr: number[] = [], colors: string[] = ['#0CC255', '#F7D006']): string {
    const root = document.createElement('div')

    const renderedComponent = createApp({
      name: 'GraphRenderer',
      components: {
        DonutChart,
      },
      render: (h: any) => {
        return h(MarkerGraphContainer, { initialData: arr, colors: colors })
      },
    })
    

    const template = renderedComponent.mount(root)

    renderedComponent.unmount()

    const content = template.$el.querySelector('svg').innerHTML
    
    return `<g transform="scale(0.27)">${content}</g>`
  }
}