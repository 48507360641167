import {createI18n} from 'vue-i18n'
import enGb from '@/lang/en-gb.json'
import enUs from '@/lang/en-us.json'
import { regionConfig } from '@/region-config'

export type Locale = 'en-gb' | 'en-us'

const regionCfg = regionConfig()

export function numberFormats() {
  return {
    'en-us': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
      decimal: {
        style: 'decimal',
        maximumFractionDigits: 2,
        maximumSignificantDigits: 4,
      },
      percent: {
        style: 'percent',
        maximumFractionDigits: 2,
        useGrouping: false,
      },
    },
    'en-gb': {
      currency: {
        style: 'currency',
        currency: 'GBP',
      },
      decimal: {
        style: 'decimal',
        maximumFractionDigits: 4,
      },
      percent: {
        style: 'percent',
        maximumFractionDigits: 2,
        useGrouping: false,
      },
    },
  }
}

export const i18n = createI18n({
  legacy: false,
  locale: regionCfg.locale,
  fallbackLocale: 'en-gb',
  numberFormats: numberFormats(),
  messages: {
    'en-gb': enGb,
    'en-us': enUs,
  },
})
