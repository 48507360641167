
export class SiteModel {
  public static ModelScoreLabelMapper = new Map<string, string>([
    ['traffic_count_r-1200', 'Traffic count'],
    ['income:income_per_household_50th_percentile', 'Income median'],
    ['poi_r-400', 'POI count'],
    ['population_age:population_all_ages', 'Population density'],
  ])
  
  public static ModelScoreValueMapper = new Map<string, string>([
    ['low_poi', 'Low'],
    ['mid_poi', 'Medium'],
    ['high_poi', 'High'],
    ['very_high_poi', 'Very High'],
    ['low_population', 'Low'],
    ['mid_population', 'Medium'],
    ['high_population', 'High'],
    ['low_income', 'Low'],
    ['mid_income', 'Medium'],
    ['high_income', 'High'],
    ['low_traffic', 'Low'], 
    ['mid_traffic', 'Medium'],
    ['high_traffic', 'High'],
  ])  
}


export class SiteUtils {
  static sortByUUID = (a: { id: string }, b: { id: string }): number => {
    const uuidA = a.id.toLowerCase()
    const uuidB = b.id.toLowerCase()
    return uuidA.localeCompare(uuidB)
  }
}