import ChargerProposedSlow from '@/assets/map/markers/charger-proposed-slow.svg'
import ChargerApprovedSlow from '@/assets/map/markers/charger-approved-slow.svg'
import ChargerReviewSlow from '@/assets/map/markers/charger-review-slow.svg'
import ChargerRejectedSlow from '@/assets/map/markers/charger-rejected-slow.svg'
import {OriginalWorkspaceLabel, SiteStatus, StatusIcon, WorkspaceSite} from '@/types/workspace'
import {DateUtils} from '@/utils/date.utils'
import {i18n} from '@/plugins/i18n'
import { ChargerCategorization } from '@/types/app'


// Charger model
export interface Connector {
  type: string
  output: string | null
  standard: string
}

export interface ChargerPoint {
  id?: string,
  title: string,
  connectors: Connector[]
}

export interface ChargerOutput {
  id: string
  title: string
  sockets: Connector[]
}

export interface ChargerStation {
  chargerPoint: ChargerPoint[]
  id: string
  title: string
  archived: boolean
}

export interface Charger {
  quantity: number
  title: string
}

export interface SiteCharger extends Charger {
  id: string
}

export interface ChargersPerSiteData {
  number_of_chargers: number
  number_of_sockets: number
  charger_setup: string
  // Other potential socket count fields can be added dynamically
  [key: string]: number | string
}

export interface ChargerReportData {
  chargers_per_site: {
    [siteId: string]: ChargersPerSiteData
  }
}

export interface CompetitionCharger {
  cp_types: Record<string, number>,
  categorization: ChargerCategorization,
}

export interface StatusUpdate {
  id: string,
  status: string,
  position?: number
}

export interface ChargerStationOptions extends ChargerStation {
  disabled: boolean,
}


export class ChargerModelMapper {
  public static createCharger(count: number = 1): ChargerStation {
    return {
      id: '',
      title: '',
      archived: false,
      chargerPoint: [
        this.createChargerPoint(count),
      ],
    }
  }
  
  public static createChargerPointName(count: number): string {
    return `${i18n.global.t('terminology.chargePoint')}/EVSE ${count}`
  }

  public static createChargerPoint(count: number = 1): ChargerPoint {
    return {
      title: this.createChargerPointName(count),
      connectors: [this.createSocket()],
    }
  }

  public static createSocket(): Connector {
    return {
      type: '',
      output: null,
    }
  }
}

// Header Marker model

export enum MarkerActionType {
  none = 'none',
  delete = 'delete',
  archive = 'archive',
  unarchive = 'unarchive'
}

export enum MarkerStatusState {
  locked = 'locked',
  drag = 'drag',
  none = 'none',
}
  
export type OriginalLabel = 'REJECTED' | 'APPROVED' | 'PROPOSED' | 'VERIFIED' | 'IN REVIEW'

export interface ConfigurableItem {
  id: string
  text: string
  status: MarkerStatusState
  action: MarkerActionType
  icon: string
  position: number

}


export interface StatusHistoryOutput {
  id: number
  updated_at: string
  old_value: string
  new_value: string
  status_type: string
}

export interface StatusHistory {
  event: string
  date: string
  user: string
  description: string
}

export interface ConfigurablePlanningStatus extends ConfigurableItem {
  isDefault: boolean
  originalLabel: OriginalLabel | string
}

export interface WorkspaceStatusBase {
  id: string
  status: string
  position: number
}

export interface WorkspaceStatus extends WorkspaceStatusBase {
  archived: boolean
  original_label: string
  is_default: string
}

export interface ConfigurableWorkspaceStatus extends ConfigurableItem {
  originalLabel: OriginalWorkspaceLabel | string
}

const chargerIcons = new Map<OriginalLabel, string>([
  ['PROPOSED', ChargerProposedSlow],
  ['APPROVED', ChargerApprovedSlow],
  ['REJECTED', ChargerRejectedSlow],
])

const selectIcon = (payload: SiteStatus): string => {

  const status = MarkerStatusModelMapper.selectStatusIcon(payload)
  
  if (payload.is_default) {
    return chargerIcons.get('PROPOSED') as string
  } else if (status.match(/Approved/gi)) {
    return chargerIcons.get('APPROVED') as string
  } else if (status.match(/Rejected/gi)) {
    return chargerIcons.get('REJECTED') as string
  }

  return ChargerReviewSlow
}

export class MarkerStatusModelMapper {

  public static toSiteStatuses = (sites: WorkspaceSite[], statuses: SiteStatus[]): WorkspaceSite[] => {
  
    const dict = statuses.reduce((acc, item) => {
      if (!acc[item.status] && !item.archived) {
        acc[item.status] = item
      }
  
      return acc
    }, {} as { [key: string]: SiteStatus })
  
    return sites.map(site => {
      const data = dict[site.properties.status]
      site.properties.statusIcon = this.selectStatusIcon(data) as StatusIcon
      return site
    })
  }

  public static toSiteStatus = (site: WorkspaceSite, statuses: SiteStatus[]): WorkspaceSite => {
    const dict = statuses.reduce((acc, item) => {
      if (!acc[item.status] && !item.archived) {
        acc[item.status] = item
      }
  
      return acc
    }, {} as { [key: string]: SiteStatus })
  
    const data = dict[site.properties.status]
    site.properties.statusIcon = this.selectStatusIcon(data) as StatusIcon
    return site

  }

  public static toConfigurableMarkerStatusItem(payload: SiteStatus): ConfigurablePlanningStatus {
    const action = payload.protected ? MarkerActionType.none :
      payload.archived ? MarkerActionType.unarchive : MarkerActionType.archive

    const status = payload.archived ? MarkerStatusState.none:
      payload.protected ? MarkerStatusState.locked: MarkerStatusState.drag

    return {
      status,
      action,
      icon: selectIcon(payload),
      text: payload.status,
      id: payload.id,
      isDefault: payload.is_default,
      position: payload.position,
      originalLabel: payload.original_label,
    }
  }

  public static defaultStatuses(): { [key: string]: ConfigurablePlanningStatus[] } {
    return {
      top: [
        { status: MarkerStatusState.locked, icon: ChargerProposedSlow, text: 'Proposed', action: MarkerActionType.none, id: '1', position: 0, isDefault: true, originalLabel: 'PROPOSED' },
      ],
      dynamic: [
        { status: MarkerStatusState.drag, icon: ChargerReviewSlow, text: 'In review', action: MarkerActionType.delete, id: '4', position: 0, isDefault: false, originalLabel: 'IN REVIEW' },
        { status: MarkerStatusState.drag, icon: ChargerReviewSlow, text: 'Verified', action: MarkerActionType.delete, id: '5', position: 0, isDefault: false, originalLabel: 'VERIFIED' },

      ],
      bottom: [
        { status: MarkerStatusState.locked, icon: ChargerApprovedSlow, text: 'Approved', action: MarkerActionType.none, id: '2', position: 0, isDefault: false, originalLabel: 'APPROVED' },
        { status: MarkerStatusState.locked, icon: ChargerRejectedSlow, text: 'Rejected', action: MarkerActionType.none, id: '3', position: 0, isDefault: false, originalLabel: 'REJECTED' },
      ],
    }
  }

  // if id is -1 then we know that the status is not stored in the database
  public static addDefaultStatus(): ConfigurablePlanningStatus {
    return { status: MarkerStatusState.drag, icon: ChargerReviewSlow, text: 'Custom', action: MarkerActionType.none, 'id': '-1', position: 0, isDefault: false, originalLabel: 'none' }
  }

  public static selectStatusIcon = (payload?: SiteStatus): string => {
    if (!payload) {
      return 'in review'
    }

    const label = payload.original_label.toUpperCase()

    if (payload.is_default || label === 'PROPOSED') {
      return 'Proposed'
    } else if (label === 'VERIFIED') {
      return 'Verified'
    } else if (label === 'APPROVED') {
      return 'Approved'
    } else if (label === 'REJECTED') {
      return 'Rejected'
    }
  
    return 'in review'
  }
}

export class WorkspaceStatusModelMapper {
  public static getDefaultValues = (s: WorkspaceStatus): boolean => {
    return s.original_label === 'Active' || s.original_label === 'Completed'
  }

  public static toConfigurableMarkerStatusItem(payload: WorkspaceStatus): ConfigurableWorkspaceStatus {

    const action = WorkspaceStatusModelMapper.getDefaultValues(payload) ? MarkerActionType.none
      :payload.archived ? MarkerActionType.unarchive : MarkerActionType.archive

    const status = payload.archived ? MarkerStatusState.none:
      WorkspaceStatusModelMapper.getDefaultValues(payload) ? MarkerStatusState.locked: MarkerStatusState.drag

    return {
      status,
      action,
      text: payload.status,
      id: payload.id,
      position: payload.position,
      originalLabel: payload.original_label,
      icon: '',
    }
  }

  public static addDefaultStatus(index: number): ConfigurableWorkspaceStatus {
    return {
      status: MarkerStatusState.drag,
      action: MarkerActionType.archive,
      text: 'Custom',
      id: '-1',
      position: index,
      originalLabel: 'Custom',
      icon: '',
    }
  }
}


export class WorkspaceLogHistory {
  public static toLog(item: StatusHistoryOutput): StatusHistory {
    return {
      event: item.status_type,
      date: DateUtils.format(item.updated_at, DateUtils.dateAndTime),
      user: '',
      description: `Status changed from ${item.old_value} to ${item.new_value}`,
    }
  }
}
