import { wait } from '@/libs/helpers'
import { WorkspaceSite } from '@/types/workspace'
import { defineStore } from 'pinia'

const changeMapSize = () => {
  const sidebarContainerSize = 'max(35%, 450px)' 
  const mapClassContainer = '.map'

  const expand = () => {
    const el = document.querySelector<HTMLElement>(mapClassContainer)
    if (el) {
      el.style.width = '100%'
    }
  } 
  const shrink = () => {
    const el = document.querySelector<HTMLElement>(mapClassContainer)
    if (el) {
      el.style.width = `calc(100% - ${sidebarContainerSize})`
    }
  }

  return {
    expand, 
    shrink,
  }
}

interface GlobalSidebarState {
  showSideBar: boolean,
  site: WorkspaceSite | null,
  showBackButton: boolean
}

const changeMapContainer = changeMapSize()

// when using the sidebar set the property :no-close-on-route-change="true"
// and manually close the sidebar. There is a bug where
// the sidebar execute to many times,

export const useSidebarStore = defineStore('sidebarStore', {

  state: (): GlobalSidebarState => ({
    showSideBar: false,
    site: null,
    showBackButton: false,
  }),

  actions: {
    isSiteOpened(siteId: string): boolean {
      return this.site?.id === siteId
    },

    async open(site: WorkspaceSite | null = null, showBackButton = false): Promise<void> {
      this.showSideBar = true
      this.site = site
      this.showBackButton = showBackButton
      changeMapContainer.shrink()
      await wait(400)
    },
  
    async close() {
      this.showSideBar = false
      this.site = null
      this.showBackButton = false
      changeMapContainer.expand()
      await wait(400)
    },
  
    onSidebarChange(state: boolean) {
      this.showSideBar = state 
      state ? changeMapContainer.shrink() : changeMapContainer.expand() 
    },
  },
})