import { ScaleType } from '@/types/app'
import { regionConfig } from '@/region-config'

export function mapRangeToBreakPoints(
  value: number,
  range: number[],
  min: number,
  max: number,
): number {
  if (range[range.length -1] > max) {
    throw new Error('Range break points overflow the max value')
  }

  if (value >= min) {
    for (let i = 0; i < range.length; i++) {
      if (range[i] > value) {
        return i
      }
    }
  }

  return -1
}

/**
 * Map value from one range to another range of indexes. Use this index form determining color/opacity in range of data.
 * @param n value that you want to map
 * @param rangeMinMax minimum and maximum of whole dataset
 * @param rangeIndex range of indexes. Lower bound usually 0 and upper range.length
 * @param scale when mapping use different scales: linear / logarithmic / exponential / sqrt
 */
export function mapRangeToBuckets(
  n: number,
  [min, max]: [number, number],
  [lowerIndex, upperIndex]: [number, number],
  scale?: ScaleType,
): number {
  scale = scale || 'linear'

  const transformedN = transformValue(n, scale)

  const transformedMinMaxRange: [number, number] = [transformValue(min, scale), transformValue(max, scale)]

  let transformedResult =
    lowerIndex -
    1 +
    ((transformedN - transformedMinMaxRange[0]) * (upperIndex + 1 - lowerIndex)) /
      (transformedMinMaxRange[1] - transformedMinMaxRange[0])

  if (Math.ceil(transformedResult) < lowerIndex) {
    transformedResult = lowerIndex
  }

  return Math.ceil(transformedResult)
}

function transformValue(value: number, scale: ScaleType): number {
  switch (scale) {
    case 'linear':
      return value
    case 'logarithmic':
      return Math.log(value)
    case 'exponential':
      return Math.exp(value)
    case 'sqrt':
      return Math.sqrt(value)
    default:
      return value
  }
}
/**
 * Inverse function to Map(). Used for figuring out the upper bound of the bucket at that index.
 * Will return an array of breakpoints. First element is min and last is max.
 * @param rangeMinMax minimum and maximum of whole dataset
 * @param rangeIndex range of indexes. Lower bound usually 0 and upper range.length
 * @param scale when inverting use the same scale as when rendering: linear / logarithmic / exponential / sqrt
 */
export function mapInverse(
  [min, max]: [number, number],
  [, upperIndex]: [number, number],
  scale: ScaleType,
): number[] {
  let buckets = linspace(transformValue(min, scale), transformValue(max, scale), upperIndex + 1)
  buckets = buckets.map((value) => {
    return inverseTransformValue(value, scale)
  })
  console.log(buckets)
  return buckets
}

function inverseTransformValue(value: number, scale: ScaleType): number {
  switch (scale) {
    case 'linear':
      return value
    case 'logarithmic':
      return Math.exp(value)
    case 'exponential':
      return Math.log(value)
    case 'sqrt':
      return value ** 2
    default:
      return value
  }
}

// linspace same as in NumPy
function linspace(start: number, stop: number, num: number): number[] {
  if (num === 1) {
    return [start]
  }

  const step = (stop - start) / (num - 1)
  return Array.from({ length: num }, (_, i) => start + i * step)
}

/**
 * Will round the number as such:
 * 1) n is [0, 1] => Four decimal places
 * 2) n is [1, 10] => One decimal place
 * 3) n is [10, 100] => Zero decimal places
 * 4) n in [100, 1000] => Round ones
 * 5) n > 1000 => Round ones and tens
 * @param n Number you want to round
 */
export function numberFormatting(n: number | string): string {
  // Handle numbers as numbers and as strings
  const nParsed = typeof n === 'string' ? parseFloat(n) : n

  // Handle negative numbers as absolute and then apply the original sign
  const sign = nParsed < 0 ? -1 : 1
  const nAbs = Math.abs(nParsed)

  let result: number

  if (nAbs >= 0 && nAbs < 1) {
    result = parseFloat(nAbs.toFixed(4)) * sign
  } else if (nAbs >= 1 && nAbs < 10) {
    result = parseFloat(nAbs.toFixed(1)) * sign
  } else if (nAbs >= 10 && nAbs < 100) {
    result = Math.round(nAbs) * sign
  } else if (nAbs >= 100 && nAbs <= 1000) {
    result = Math.round(nAbs / 10) * 10 * sign
  } else {
    result = Math.round(nAbs / 100) * 100 * sign
  }

  const region = regionConfig()
  return result.toLocaleString(region.locale)
}

export function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

export function toDegrees(radians: number): number {
  return radians * (180 / Math.PI)
}
