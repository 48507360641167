import { ChargerStation, SiteCharger } from '@/models/admin/Configuration.model'
import { ChargerCategorization, ChargerSpeed } from '@/types/app'
import { RegionUtils } from '@/utils/region.utils'
import { pretty } from '@/libs/texts'


export class ChargerUtils {

  public static getMaxPower(charger: SiteCharger[], list: ChargerStation[]): number {
    const mapData: number[] = (charger
      .flatMap(d => {
        return list.find(l => l.id === d.id)
      })
      .filter(d => d)
      .flatMap(d => {
        return (d?.chargerPoint || []).flatMap(c => c.connectors.map(d => d.output))
      }) || [])
      .map((d: any) => parseInt(d, 10))

    return Math.max(...mapData)

  }

  public static getConnectorTypeFromMaxPower(maxPowerkW: number): string {
    // Return connector type matching max power
    if (maxPowerkW < 4) {
      return 'slow'
    } else if (maxPowerkW <= 25) {
      return 'fast'
    } else if (maxPowerkW <= 50) {
      return 'rapid'
    } else {
      return 'ultra_rapid'
    }
  }

  public static serialize(chargerStation: any): ChargerStation {
    const chargerPoint = chargerStation.charge_points || []
    return {
      id: chargerStation.id,
      title: chargerStation.title,
      archived: chargerStation.archived,
      chargerPoint,
    }
  }
  
  public static reportTemplateDictionary = (allStationChargers: ChargerStation[], siteChargers: SiteCharger[]) => {
    const siteChargerDictionary = siteChargers.reduce((acc: { [key: string]: SiteCharger[] }, item: any) => {
      if (!acc[item.siteId]) {
        acc[item.siteId] = item.collection
      } 
      return acc
    }, {} as { [key: string]: SiteCharger[] })

    const siteStationDictionary = allStationChargers.reduce((acc: { [key: string]: ChargerStation }, item: ChargerStation) => {
      if (!acc[item.id]) {
        acc[item.id] = item
      } 
      return acc
    }, {} as { [key: string]: ChargerStation })
  
    return {
      siteChargerDictionary,
      siteStationDictionary,
    }
  }

  public static getHighestActiveType(categorization: ChargerCategorization) {
    return ChargerUtils.getActiveTypes(categorization)[0]
  }

  public static getActiveTypes(categorization: ChargerCategorization) {
    const types: ChargerSpeed[] = []

    if (RegionUtils.isUk()) {
      if (categorization.ultra_rapid) {
        types.push('ultra_rapid')
      }
      if (categorization.rapid) {
        types.push('rapid')
      }
      if (categorization.fast) {
        types.push('fast')
      }
      if (categorization.slow) {
        types.push('slow')
      }

    } else {
      if (categorization.level_3_dc_fast) {
        types.push('level_3_dc_fast')
      }
      if (categorization.level_2) {
        types.push('level_2')
      }
    }

    return types
  }

  public static getSpeedLabel(speed: string) {
    if (RegionUtils.isUs()) {
      return speed === 'level_3_dc_fast' ? 'Level 3 / DC Fast' : pretty(speed)
    } else if (RegionUtils.isUk()) {
      return pretty(speed)
    } else {
      if (speed === 'level_3_dc_fast') {
        return 'DC'
      } else {
        return 'AC'
      }
    }
  }
}
