import {
  DatasetData,
  LayerPolygonData,
  ParkingPolygonData,
  PolygonPaintStyle,
  PropertyFilter,
  SimpleOption,
} from '@/utils/layers/layout-config'
import {PARKING_STYLE, PRETTY_BLUE_WHITE_STYLE, PRETTY_SINGLE_STYLE} from '@/utils/layers/layerStyles.utils'
import {CountryCode} from '@/types/app'
import {layersUK} from '@/utils/layers/layers-uk'
import {layersUS} from '@/utils/layers/layers-us'
import GlobalUtils from '@/utils/global.utils'
import {layersDE} from '@/utils/layers/layers-de'
import {RoleManager} from '@/libs/RoleManager'
import {Organization} from '@/auth/roles'

export const featureOverlayDisabled = (organizationStore: RoleManager, organizationName: Organization, dataset: string): boolean => {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS.some(layers => layers.dataset === dataset) 
    case CountryCode.DE:
      return layersDE.some(layers => layers.dataset === dataset) 
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName).some(layers => layers.dataset === dataset)  
    default:
      return false 
  }
}

export function layerConfiguration(organizationStore: RoleManager, organizationName: Organization): DatasetData[] {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS
    case CountryCode.DE:
      return layersDE
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName)
    default:
      return []
  }
}

export function getGenericLayerConfiguration(name: string, style?: PolygonPaintStyle) {
  return new LayerPolygonData({
    name: name,
    group: 'Custom data',
    dataset: name,
    options: new SimpleOption('value'),
    minZoom: 15,
    paintStyle: style ?? PRETTY_SINGLE_STYLE,
    paintStyleOptions: {
      'roadmap': style ?? PRETTY_SINGLE_STYLE,
      'hybrid': style ?? PRETTY_BLUE_WHITE_STYLE,
    },
    disableMinMax: false,
    genericApi: true,
    tooltips: [{ text: 'Name:', property: 'name' }, { text: 'Value:', property: 'value' }],
  })
}

export const parkingMaxZoom = 9

export function getParkingPolygon(parkingOperators: string[], operatorsCategories: string[]) {
  const filterPropertis: PropertyFilter[] = []

  if (parkingOperators.length > 0) {
    filterPropertis.push({
      property_name: 'parking_operator_class',
      filter: parkingOperators,
    })
  }
  if (operatorsCategories.length > 0) {
    filterPropertis.push({
      property_name: 'parking_category_class',
      filter: operatorsCategories,
    })
  }

  return new ParkingPolygonData({
    name: 'Parking data',
    group: 'parking',
    dataset: 'PARKOPEDIA_PARKINGS',
    filterProperties: filterPropertis,
    minZoom: parkingMaxZoom,
    disableMinMax: true,
    options: new SimpleOption('parking'),
    paintStyle: PARKING_STYLE,
  })
}

