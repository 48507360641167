import {ScoreColorPresentation, SiteAssessmentScore} from '@/models/SiteSelection/SiteScore.model'

export type SupportedRegion = RegionCode.UK | RegionCode.US

// Represents the UK
export const UK =
  'POLYGON((5.863740590767872 61.2742641903237, 5.863740590767872 47.90723621213962, -16.776678415579784 47.90723621213962, -16.776678415579784 61.2742641903237, 5.863740590767872 61.2742641903237))'

export enum StayDurationColor {
  Long = '#FDA145',
  Short = '#10B8CF',
}

export type ChargerOwnership = 'own' | 'competition' | 'econnect' | 'none'

export type ChargerUKSpeed = 'slow' | 'fast' | 'rapid' | 'ultra_rapid'
export type ChargerUSASpeed = 'level_2' | 'level_3_dc_fast'
export type ChargerSpeed = ChargerUKSpeed | ChargerUSASpeed

/**
 * Describes the type of charger markers that can be seen inside a workspace.
 * Different from charger speed.
 */
export type ChargerMarkerType =
  | 'own'
  | 'proposed'
  | 'ai generated'
  | 'in review'
  | 'rejected'
  | 'approved'
  | 'competition'
export const chargerMarkerTypes: ChargerMarkerType[] = [
  'own',
  'proposed',
  'ai generated',
  'in review',
  'rejected',
  'approved',
  'competition',
]

export enum ChargerType {
  slow = 'slow',
  fast = 'fast',
  rapid = 'rapid',
  own = 'own',
  proposed = 'proposed',
  ultraRapid = 'ultra_rapid',
}

export interface ChargerIcon {
  icon: string,
  speed: string,
}

export const chargerSpeedOrder: Record<ChargerSpeed, number> = {
  slow: 1, fast: 2, rapid: 3, ultra_rapid: 4, level_2: 1, level_3_dc_fast: 2,
}

export interface CheckboxOption {
  label: string
  value: string
}

export interface CheckboxItem {
  value: string
  label?: string
  icon?: string
  disabled?: boolean
  iconSrc: string
  multipleItems?: boolean
  selectedSubOptionValue?: string,
}

export interface CheckboxSubOptionItem extends CheckboxItem {
  subOptions: CheckboxOption[]
  multipleItems: false
}

export interface MultipleCheckboxItem extends CheckboxItem {
  subOptions: CheckboxOption[][]
  subLabels: string[]
  multipleItems: true
}

export interface MinMax {
  min: number
  max: number
}

export interface Substation {
  spn?: string
  category?: string
  last_updated?: string
  substation_type?: 'lss' | 'pss'
  name?: string
  label: string
  dno?: string
  gsp?: string
  bsp?: string
  dhr_non_firm?: number
  dhr_firm?: number
  dhr?: number
  id?: string
  capacity_range: string,
}

export interface FilterItem {
  value: string
  label?: string
  icon?: string
  selected?: boolean
  disabled?: boolean
  checkbox?: boolean
}

export type Result = {
  type: 'MapScale' | 'RangeGradientScale'
  values?: ResultValue[]
} & (MapResult | RangeResult)

type MapResult = {
  type: 'MapScale'
  colorMap?: Map<string, string>
}

type RangeResult = {
  type: 'RangeGradientScale'
  colorScale?: string[]
}

export interface ResultValue {
  label: string
  value: number
  displayValue?: string
  position?: number

  // references to map elements
  polygon?: google.maps.Polygon
  marker?: google.maps.Marker
  color?: string
}

export interface ShowResultArgs {
  show?: boolean
  title?: string
  result: Partial<Result>
}

export declare interface POIFeatureProperties {
  category: string
  parentCategory?: string
  dwellTime?: string
  name?: string
}

export interface EvseInfo {
  /**
   * Location Type as per OCPI 2.1.1
   */
  type?: string
  max_power_categorization: ChargerUKSpeed
  power_type_categorization: ChargerUSASpeed
  facilities?: string[]
  parking_restrictions?: string[]
  connectors: EvseConnector[]
}

export interface EvseConnector {
  standard: string
  max_power: number
}

export interface ChargerUtilization {
  last_active_date: string
  average_daily_kwh: number
  average_occupancy: number
  average_daily_session_count: number
  average_daily_kwh_score: number
  average_occupancy_score: number
  last_active_sessions: string
}

export interface PredictedUtilizationFeatureProperties {
  charge_point_id: number
  value: number
}

export interface ChargerFeatureProperties {
  id: string
  name: string
  owner_name: string
  label?: string
  categorization: ChargerCategorization
  cp_type?: ChargerSpeed // deprecated. used for our chargers only
  evse_count: number
  address: string

  max_power: number
  evse_info: EvseInfo[]
  operator_name: string
  utilization: ChargerUtilization
}

export declare interface WardFeatureProperties {
  name: string
  id: number
  boundingbox: google.maps.LatLngBounds
  centroid: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export interface RegionFeatureProperties {
  id: number
  name: string
  display_name: string
  polygon?: google.maps.Polygon
  bbox?: google.maps.LatLngBounds
  center?: google.maps.LatLng
}

interface PolygonProperties {
  polygon: google.maps.Polygon
  bbox: google.maps.LatLngBounds
  center: google.maps.LatLng
}

export interface AreaProperties extends PolygonProperties {
  id: number
  name: string
  display_name: string
  parent_id?: number
}

export interface CountryProperties extends Omit<AreaProperties, 'polygon' | 'bbox' | 'center'> {
  bounding_box: string
  centroid: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export type DwellTimeType = 'short' | 'long'

export interface POICategory {
  name: string
  parent: string
  dwellTime: DwellTimeType 
  count?: number
}

export interface Location {
  zoom: number
  center: { lat: number; lng: number }
  label: string
}

export interface ChargePointModel {
  id: number
  name: string
}

export interface ChargePoint {
  charge_point_id: number
  assessment_name: string
  aggregate_score_name: SiteAssessmentScore,
  aggregate_score_color: ScoreColorPresentation,

  // unfortunately we can not bet on consistency of the data
  // structure we get from the backend
  value?: number
  utilisation?: number
  utilization?: number
}

export type ChargePointDetails = Record<
  string,
  Record<string, number | string | null>
>


/**
 * map center, zoom
 *
 * This per-user settings will be loaded from the backend eventually
 */
export const locations: Record<string, Location> = {}
locations.gm = {
  zoom: 12,
  center: { lat: 53.4200904616, lng: -2.3299764318 },
  label: 'Manchester, Great Britain',
}
locations.dv = {
  zoom: 11,
  center: { lat: 51.2203763, lng: 1.1089383 },
  label: 'Dover, Great Britain',
}
locations.lc = {
  zoom: 11,
  center: { lat: 54.0752838, lng: -2.9114759 },
  label: 'Lancaster, Great Britain',
}
locations.bp = {
  zoom: 11,
  center: { lat: 53.834548, lng: -3.035312 },
  label: 'Blackpool, Great Britain',
}
locations.ws = {
  zoom: 11,
  center: { lat: 50.916667, lng: -0.5 },
  label: 'West Sussex, Great Britain',
}
locations.ld = {
  zoom: 11,
  center: { lat: 51.50106075, lng: -0.15451935 },
  label: 'London, Great Britain',
}
locations.uk = {
  zoom: 6,
  center: { lat: 54.96313889658664, lng: -5.169329707142838 },
  label: 'UK',
}

export type MapType = 'roadmap' | 'satellite' | 'hybrid'

export type ScaleType = 'linear' | 'logarithmic' | 'exponential' | 'sqrt'


export interface ChargerDetails {
  categorization: ChargerCount
  cpo_list: string[]
}


export interface ChargerCategorization {
  slow: boolean
  fast: boolean
  rapid: boolean
  ultra_rapid: boolean
  level_2: boolean
  level_3_dc_fast: boolean
}

export interface ChargerCount {
  slow: number
  fast: number
  rapid: number
  ultra_rapid: number
  level_2: number
  level_3_dc_fast: number
}

export enum RegionCode {
  UK = 'UK',
  US = 'US',
}

export enum CountryCode {
  UK = 'UK',
  US = 'US',
  IT = 'IT',
  DE = 'DE',
  IE = 'IE',
  FR = 'FR',
  ES = 'ES',
  NL = 'NL',
  BE = 'BE',
  PL = 'PL',
  CA = 'CA',
  CH = 'CH',
  AT = 'AT',
  SE = 'SE',
  SI = 'SI',
  AU = 'AU',
}

export const countryI18n = new Map([
  [CountryCode.UK, 'en-gb'],
  [CountryCode.US, 'en-us'],
])

export interface AppRegionData {
  url: string
  countryCode: SupportedRegion
}

export enum EVCSTime {
  THIRTY = 0,
  NINETY = 1,
  ONE_HUNDRED_EIGHTY = 2
}

export const chargerTimeMapper = new Map([
  [EVCSTime.THIRTY, 30],
  [EVCSTime.NINETY, 90],
  [EVCSTime.ONE_HUNDRED_EIGHTY, 180],
])


export interface UiCheckbox {
  checked: boolean
}
