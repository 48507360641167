import {RoleManager} from '@/libs/RoleManager'

export default function (roleManagerService: RoleManager) {
  return function (app: any): void {
    app.config.globalProperties.$roles = roleManagerService
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $roles: RoleManager
  }
}
