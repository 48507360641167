import {Workspace} from '@/types/workspace'

export function getWorkspaceEventInfo(ws?: Workspace): Pick<Workspace, 'id' | 'name' | 'status' | 'status_id' | 'region_id' | 'region_name' | 'number_of_sites' | 'owner' | 'owner_id'> | undefined {
  if (!ws) {
    return
  }

  const { id, name, status, status_id, owner, region_id, number_of_sites, owner_id, region_name } = ws

  return { id, name, status, status_id, owner, region_id, number_of_sites, owner_id, region_name }
}