<template>
  <div>
    <DonutChart
      :initial-values="initialData"
      :colors="colors"
    />
  </div>
</template>
<script lang="ts">
import DonutChart from '@/components/Map/overlays/DonutChart.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MarkerGraphContainer',
  components: {
    DonutChart,
  },
  props: {
    initialData: {
      type: Array<number>,
      required: true,
    },
    colors: {
      type: Array<string>,
      required: true,
    },
  },
})
</script>
