import {regionConfig} from '@/region-config'

export type MeasurementSystem = 'metric' | 'imperial'

// Conversion constants
export const METERS_TO_FEET = 3.28084
export const FEET_TO_METERS = 1 / METERS_TO_FEET
export const METERS_TO_MILES = 0.000621371
export const MILES_TO_METERS = 1 / METERS_TO_MILES
export const KILOMETERS_TO_MILES = METERS_TO_MILES * 1000
export const SQ_METERS_TO_SQ_FEET = 10.7639
export const SQ_METERS_TO_SQ_MILES = 3.861e-7

// All API returned units are in meters/kilometers

export function isMetric(): boolean {
  const system: MeasurementSystem = regionConfig().measurementSystem
  return system === 'metric'
}

export function convertKmToMeters(amount: number) {
  if (isMetric()) {
    return amount * 1000
  }
  return amount
}

/**
 * Converts kilometers to miles if the measurement system is set to imperial
 */
export function convertBig(amount: number): number {
  if (isMetric()) {
    return amount
  }

  return amount * KILOMETERS_TO_MILES
}

/**
 * Converts meters to feet if the measurement system is set to imperial
 */
export function convertSmall(amount: number): number {
  if (isMetric()) {
    return amount
  }

  return amount * METERS_TO_FEET
}

export function reverseConvertSmall(amount: number) {
  if (isMetric()) {
    return amount 
  }
  return amount * FEET_TO_METERS
}

/**
 *
 * @param metric value will remain unchanged
 * @param imperial value will be converted to meters/kilometers
 * @param big if true, the value represents miles/km, otherwise it's meters/feet
 */
export function variableDistance(metric: number, imperial: number, big = false): { text: number, value: number } {
  if (!isMetric()) {
    return {
      text: imperial,
      // Big is multiplied by an additional 1000 as it converts km to meters
      value: big ? imperial * MILES_TO_METERS : imperial * FEET_TO_METERS,
    }
  }

  return {
    text: metric,
    value: metric,
  }
}
