import GlobalUtils from '@/utils/global.utils'

const devRoutes = {
  path: '/dev',
  component: () =>
    import(/* webpackChunkName: "dev-tools" */ './DevTools/Layout.vue'),
  children: [
    {
      path: '/',
      name: 'dev-tools/index',
      component: () => import('./DevTools/Index.vue'),

      _devNav: { hide: true },
    },
    {
      path: 'poi-icons',
      name: 'dev-tools/poi-icons',
      component: () => import('./DevTools/POIIcons.vue'),

      _devNav: { label: 'POI Icons' },
    },
  ],
}

export default GlobalUtils.isProduction() ? [] : [devRoutes]
