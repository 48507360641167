import { toLower } from 'lodash'

export interface ScoreColor {
  fill: string,
  border: string,
  text: string
}

export enum ScoreColorPresentation {
  RED = 'RED',
  LIGHT_RED = 'LIGHT_RED',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREY = 'GREY',
  LIGHT_GREEN = 'LIGHT_GREEN',
}

const siteScoreColors: ScoreColorPresentation[] = [
  ScoreColorPresentation.GREY,
  ScoreColorPresentation.RED,
  ScoreColorPresentation.LIGHT_RED,
  ScoreColorPresentation.ORANGE,
  ScoreColorPresentation.YELLOW,
  ScoreColorPresentation.LIGHT_GREEN,
  ScoreColorPresentation.GREEN,
]

export const scoreOrder = {
  [ScoreColorPresentation.GREY]: 0,
  [ScoreColorPresentation.RED]: 1,
  [ScoreColorPresentation.LIGHT_RED]: 2,
  [ScoreColorPresentation.ORANGE]: 3,
  [ScoreColorPresentation.YELLOW]: 4,
  [ScoreColorPresentation.LIGHT_GREEN]: 5,
  [ScoreColorPresentation.GREEN]: 6,
}

export type ScoreMissingReason = 'Missing User Input' | 'No data available' | 'No Assessment' | 'Type unscored' | 'Type Unscored' | 'Missing user input' | 'No Data Available'
export type SiteAssessmentScore = 'Very Low' | 'Very Poor' | 'Very High' | 'High' | 'Medium' | 'Low' | 'Mid' | 'Mid-High' | 'No data' | 'No data available' | 'Poor' | 'Good' | 'Better' | 'Best' | ScoreMissingReason

export const missingScoreMessages: ScoreMissingReason[] = [
  'Missing User Input', 'No data available', 'No Assessment', 'Type unscored',
]

export const missingScoreMessagesToLowerCase: string[] = missingScoreMessages.map(toLower)


export function getComponentColor(presentation: ScoreColorPresentation): ScoreColor {
  const scoreColor = siteColors.get(presentation)
  if (!scoreColor) {
    throw new Error(`component colors ${presentation} not found`)
  }
  return scoreColor
}

const siteColors = new Map<ScoreColorPresentation, ScoreColor>([
  [ScoreColorPresentation.GREEN, {
    fill: '#009D3B',
    border: '#9A9A9A',
    text: '#FFFFFF',
  }],
  [ScoreColorPresentation.LIGHT_GREEN, {
    fill: '#8EC539',
    border: '#9A9A9A',
    text: '#014D00',
  }],
  [ScoreColorPresentation.ORANGE, {
    fill: '#FFDAB9',
    border: '#9A9A9A',
    text: '#212529',
  }],
  [ScoreColorPresentation.YELLOW, {
    fill: '#FFE270',
    border: '#9A9A9A',
    text: '#212529',
  }],
  [ScoreColorPresentation.LIGHT_RED, {
    fill: '#FF4646',
    border: '#9A9A9A',
    text: '#FFFFFF',
  }],
  [ScoreColorPresentation.RED, {
    fill: '#B30000',
    border: '#9A9A9A',
    text: '#FFFFFF',
  }],
  [ScoreColorPresentation.GREY, {
    fill: '#BCBEBF',
    border: '#9A9A9A',
    text: '#FFFFFF',
  }],
])

export const siteScoreStyleOptionsMap: Record<string, google.maps.Data.StyleOptions> = Object.fromEntries(siteScoreColors.map(color => {
  return [
    color,
    {
      strokeColor: getComponentColor(color).fill,
    },
  ]
}))
