import GlobalUtils from '@/utils/global.utils'
import {CountryCode} from '@/types/app'

type SupportedFormats = 'en-gb' | 'en-us'

export class RegionUtils {
  public static hasProjectedCoordinateSystemAsDefault(): boolean {
    return GlobalUtils.getAppCountry() === CountryCode.UK
  }

  public static isUk(): boolean {
    return GlobalUtils.getAppCountry() === CountryCode.UK
  }

  public static isUs(): boolean {
    return GlobalUtils.getAppCountry() === CountryCode.US
  }

  public static isDe(): boolean {
    return GlobalUtils.getAppCountry() === CountryCode.DE
  }

  public static getNumberFormat(): SupportedFormats {
    return this.isUs() ? 'en-us' : 'en-gb'
  }
}
