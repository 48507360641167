import { format } from 'date-fns'


export class DateUtils {
  public static date = 'dd MMM yyyy'
  public static dateAndTime = 'k:mm dd MMM yyyy'
  public static format(date: string, formatType = 'dd MMM yyyy'): string {
    return format(new Date(date), formatType)
  }
}

/**
 * Returns true if the date falls between the start and end date
 * @param dateString
 * @param start
 * @param end
 */
export function filterDate(dateString: string, start: Date | null, end: Date | null): boolean {
  const date = new Date(dateString)

  if (!start && end) {
    return date <= end
  }

  if (!end && start) {
    return date >= start
  }

  if (start && end) {
    return date >= start && date <= end
  }

  return true
}