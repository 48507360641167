<template>
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 160 160"
  >
    <g>
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius + 18"
        stroke="#B0B7BF"
        stroke-width="2"
        fill="white"
      />
    </g>
    <g
      v-for="(value, index) in initialValues"
      :key="index"
    >
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius"
        :stroke="colors[index]"
        :stroke-width="strokeWidth"
        :stroke-dasharray="adjustedCircumference"
        :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)"
        :transform="returnCircleTransformValue(index)"
        fill="transparent"
      />
      <text />
    </g>
    <g>
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius - 5"
        fill="white"
      />
      <circle
        :cx="cx"
        :cy="cy"
        :r="radius - 10"
        stroke="#B0B7BF"
        stroke-width="2"
        fill="white"
      /> 
    </g>
    <g transform="translate(68 -5)">
      <line
        x1="10"
        y1="8"
        x2="10"
        y2="35"
        stroke-width="3"
        stroke="#B0B7BF"
      /></g>
    <g transform="translate(68 120)">
      <line
        x1="10"
        y1="12"
        x2="10"
        y2="35"
        stroke-width="3"
        stroke="#fff"
      /></g>
    <slot />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface DonutChartDate {
    angleOffset: number
    chartData: { degrees: number}[]
    cx: number
    cy: number
    radius: number
    strokeWidth: number 
}


export default defineComponent({
  props: {
    initialValues: {
      type: Array<number>,
      required: true,
    },
    colors: {
      type: Array<string>,
      default: () => ['#0CC255', '#F7D006'],
      require: true,
    },
  },
  data(): DonutChartDate {
    return {
      angleOffset: 91,
      chartData: [{ degrees: 91 }, { degrees: 91 }],
      cx: 80,
      cy: 80,
      radius: 60,
      strokeWidth: 30,
    }
  },
  computed: {
    adjustedCircumference() {
      return this.circumference - 2
    },
    circumference() {
      return 2 * Math.PI * this.radius
    },
  },
  methods: {
    calculateStrokeDashOffset(dataVal: number, circumference: number) {
      const strokeDiff = this.dataPercentage(dataVal) * circumference

      return circumference - strokeDiff
    },
    dataPercentage(dataVal: number) {
      return (dataVal / 100) / 2
    },
    returnCircleTransformValue(index: number) {
      return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`
    },
  },
})
</script>
