import DodonaToast from '@/libs/DodonaToast'

export default function (toast: DodonaToast) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function (app: any): void {
    app.config.globalProperties.$Toast = toast
  }
}


declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $Toast: DodonaToast
  }
}

