import { CustomSiteField, CustomSiteUidField } from '@/types/custom-fields'
import { defineStore } from 'pinia'

interface CustomFieldState {
  customFields: CustomSiteField[],
}

export const useCustomFieldsStore = defineStore('custom-fields', {
  state: () => ({
    customFields: [],
  } as CustomFieldState),

  actions: {
    async fetch() {
      this.customFields = await this.api.getCustomFields()
    },
    async save(customFields: CustomSiteUidField[]) {
      for (const field of customFields) {
        if (field.deleteValues && field.custom_site_field_id) {
          await this.api.deleteCustomSiteFieldValues(field.custom_site_field_id)
        }
      }
      for (const field of customFields) {
        if (field.changed) {
          if (field.custom_site_field_id) {
            if (field.deleted) {
              await this.api.deleteCustomSiteField(field.custom_site_field_id)
            } else {
              await this.api.updateCustomSiteField(field)
            }
          } else {
            await this.api.saveCustomSiteField(field)
          }
        }
      }
      this.customFields = await this.api.getCustomFields()
    },
  },
})
