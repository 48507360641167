import { DodonaGlobalEvents, newEventBus } from '@/utils/eventBus.utils'
import { MapMouseEvent } from '@/types/google'
import GlobalUtils from '@/utils/global.utils'
export type { Filter } from '@/libs/MapLayers/types'

export interface LayerSetEventArgs {
  id: string
  tags?: string[]
  layer: google.maps.Data
}

export interface LayerDeleteEventArgs {
  id: string
}

interface Cleaner {
  clear(): void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCleaner(layer: any): layer is Cleaner {
  return typeof layer === 'object' && typeof layer?.clear === 'function'
}

/**
 * Layers plugin
 *
 * Provides an improved interface (in comparison to old filter & map) for
 * full control over data layers displayed on the map
 *
 * Responsibilities & goals:
 *  - seamless data layers management
 *  - control what is visible on the map
 *
 * Why not store (vuex?)
 *  - Google Maps provide its own data layer
 *  - no need for immutability
 *
 * @param layers
 */
export default class MapLayers {
  private layers = new Map<string, google.maps.MVCObject>()
  private map: google.maps.Map | null = null
  private tags: Record<string, string[]> = {}
  private _eventbus = newEventBus<DodonaGlobalEvents>()

  get events() {
    return this._eventbus
  }

  get allLayers() {
    return [...this.layers.entries()]
  }

  get allTags() {
    return this.tags
  }

  setMap(map: google.maps.Map | null = null): void {
    if (this.map !== map) {
      this._eventbus.$emit('map', map)
      this.map = map

      this.map?.addListener('click', (e: MapMouseEvent) => {
        console.log('click')
        console.log(e.latLng?.lng(), e.latLng?.lat())
        this._eventbus.$emit('map-click', e) 
      })
    }
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  getRestriction(): google.maps.LatLngBounds | undefined {
    if (!this.map) {
      return
    }

    const mapRestriction = this.map.get('restriction') as
      | google.maps.MapRestriction
      | undefined
    if (!mapRestriction) {
      return
    }

    return mapRestriction.latLngBounds as google.maps.LatLngBounds
  }

  set(id: string, l: google.maps.MVCObject, tags?: string[]): void {
    this.setWithoutMap(id, l, tags)

    if (l instanceof google.maps.MVCArray) {
      l.forEach((e) => e.set('map', this.map))
    } else {
      l.set('map', this.map)
    }
  }

  // Use for clustered markers!
  setWithoutMap(
    id: string,
    layer: google.maps.MVCObject,
    tags?: string[],
  ): void {
    this.delete(id)
    this.layers.set(id, layer)
    this.tag(id, tags)

    this._eventbus.$emit('layer-set', { id, layer, tags } as LayerSetEventArgs)
  }

  tag(id: string, tags: string[] = []): void {
    if (!GlobalUtils.isProduction()) {
      console.log('tagging new layer', { id, tags })
    }

    tags.forEach((tag) => {
      if (this.tags[tag]) {
        this.tags[tag].push(id)
      } else {
        this.tags[tag] = [id]
      }
    })
  }

  getTagged(tags: string[]): google.maps.MVCObject[] {
    const taggedLayers: google.maps.MVCObject[] = []

    for (const tag of tags) {
      (this.tags[tag] || []).forEach((id) => {
        const layer = this.layers.get(id)
        if (layer) {
          taggedLayers.push(layer)
        }
      })
    }

    return taggedLayers
  }

  forEachTagged(
    tag: string,
    callback: (layer: google.maps.MVCObject, id: string) => void,
  ): void {
    (this.tags[tag] || []).forEach((id) => {
      const layer = this.layers.get(id)

      if (layer) {
        callback(layer, id)
      }
    })
  }

  get(id: string): google.maps.MVCObject | undefined {
    return this.layers.get(id)
  }

  has(id: string): boolean {
    return this.layers.has(id)
  }

  /**
   * Removes one or all map layers
   * @param id
   */
  delete(id: string): void {
    if (this.layers.has(id)) {
      console.debug('removing layer by id', { id })
      const layer: google.maps.MVCObject | undefined = this.layers.get(id)

      if (layer instanceof google.maps.MVCArray) {
        layer.forEach((e) => {
          e.visible = false
          e.set('map', null)
        })
      } else if (layer instanceof google.maps.MVCObject) {
        layer.set('map', null)
      }

      if (isCleaner(layer)) {
        layer.clear()
      }

      this.layers.delete(id)
      console.debug('layer removed', { id })
      this.events.$emit('layer-removed', { id })
    }

    this.gc(id)
  }

  /**
   * Hides layer, returns true if exists
   * @param id
   */
  hide(id: string): boolean {
    if (this.layers.has(id)) {
      const layer: unknown = this.layers.get(id)

      if (layer instanceof google.maps.Data) {
        layer.setStyle({ visible: false })
      }

      return true
    }

    return false
  }

  /**
   * Shows layer, returns true if exists
   * @param id
   */
  show(id: string): boolean {
    if (this.layers.has(id)) {
      const layer: unknown = this.layers.get(id)

      if (layer instanceof google.maps.Data) {
        layer.setStyle({ visible: true })
      }

      return true
    }

    return false
  }

  /**
   * Deletes all layers using these tags
   * @param tags
   */
  deleteByTag(...tags: string[]): void {
    console.debug('removing tagged layers', tags)
    tags.forEach((tag) => {
      this.forEachTagged(tag, (l: google.maps.MVCObject, id: string) => {
        console.debug('removing tagged layer', { id, tag })
        this.delete(id)
      })
    })
    this.events.$emit('layer-removed', { tags })
  }

  /**
   * Hides all layers using these tags
   * @param tags
   */
  hideByTag(...tags: string[]): void {
    console.debug('hiding tagged layers', tags)
    tags.forEach((tag) => {
      this.forEachTagged(tag, (l: google.maps.MVCObject, id: string) => {
        console.debug('hiding tagged layer', { id, tag })
        this.hide(id)
      })
    })
  }

  gc(id?: string): void {
    for (const t in this.tags) {
      if (id) {
        // id removed, find if it was tagged anywhere and remove it..
        this.tags[t] = this.tags[t].filter((taggedId) => taggedId !== id)
      }

      if (this.tags[t].length === 0) {
        delete this.tags[t]
      }
    }
  }
}
