import {LayerPolygonData, SimpleOption} from '@/utils/layers/layout-config'
import {
  CK_HIGHWAY_BOUNDARY_STYLE,
  CK_PARKING_RESTRICTIONS_STYLE,
  CK_PROPOSED_USRN_STYLE,
} from '@/utils/layers/layerStyles.utils'

const valueTransform = (value: any) => `${value}`

export const ckHighwayBoundaryLayerName = 'Misc highway boundary HIT BNG_CK'
export const ckParkingRestrictionsLayerName = 'Parking Restrctions_CK'
export const ckProposedUsrnName = 'proposed usrn_CK'

export const CK_HIGHWAY_BOUNDARY_LAYER = new LayerPolygonData({
  name: 'Highway boundaries CK',
  group: 'Custom data',
  dataset: ckHighwayBoundaryLayerName,
  options: new SimpleOption('value'),
  paintStyle: CK_HIGHWAY_BOUNDARY_STYLE,
  disableMinMax: true,
  genericApi: true,
  tooltips: [
    {text: 'USRN', property: 'USRN', valueTransform},
    {text: 'Legend', property: 'Legend'},
    {text: 'Road name', property: 'ROADNAME'},
    {text: 'Status', property: 'STATUS_TXT'},
    {text: 'Class number', property: 'CLASS_NUMBER'},
  ],
})

export const CK_PARKING_RESTRICTIONS_LAYER = new LayerPolygonData({
  name: 'Parking restrictions CK',
  group: 'Custom data',
  dataset: ckParkingRestrictionsLayerName,
  options: new SimpleOption('value'),
  paintStyle: CK_PARKING_RESTRICTIONS_STYLE,
  disableMinMax: true,
  genericApi: true,
  tooltips: [
    {text: 'Layer', property: 'Layer'},
    {text: 'Restriction type', property: 'R_Type'},
    {text: 'Restriction', property: 'Restrictio'},
  ],
})

export const CK_PROPOSED_USRN_LAYER = new LayerPolygonData({
  name: 'Proposed USRN CK',
  group: 'Custom data',
  dataset: ckProposedUsrnName,
  options: new SimpleOption('value'),
  paintStyle: CK_PROPOSED_USRN_STYLE,
  disableMinMax: true,
  genericApi: true,
  tooltips: [
    {text: 'ID', property: 'id', valueTransform},
    {text: 'FID', property: 'fid', valueTransform},
    {text: 'USRN', property: 'usrn', valueTransform},
    {text: 'Street Type', property: 'street_type', valueTransform},
    {text: 'Import Data ID', property: 'Import data_id', valueTransform},
    {text: 'Import Data Street Type', property: 'Import data_street_type', valueTransform},
    {text: 'Import Data Proposed USRN', property: 'Import data_proposed usrn', valueTransform},
    {text: 'Import Data USRN in Surrey', property: 'Import data_usrn in surrey', valueTransform},
  ],
})