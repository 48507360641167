import { createApp } from 'vue'

import ChargerOwnSlow from '@/assets/map/markers/charger-own-slow.svg?component'
import ChargerOwnFast from '@/assets/map/markers/charger-own-fast.svg?component'
import ChargerOwnRapid from '@/assets/map/markers/charger-own-rapid.svg?component'
import ChargerOwnUltraRapid from '@/assets/map/markers/charger-own-ultra_rapid.svg?component'

import ChargerEconnectSlow from '@/assets/map/markers/charger-eConnect-slow.svg?component'
import ChargerEConnectFast from '@/assets/map/markers/charger-eConnect-fast.svg?component'
import ChargerEConnectRapid from '@/assets/map/markers/charger-eConnect-rapid.svg?component'
import ChargerEConnectUltraRapid from '@/assets/map/markers/charger-eConnect-ultra-rapid.svg?component'

import ChargerCompetitionSlow from '@/assets/map/markers/charger-competition-slow.svg?component'
import ChargerCompetitionFast from '@/assets/map/markers/charger-competition-fast.svg?component'
import ChargerCompetitionRapid from '@/assets/map/markers/charger-competition-rapid.svg?component'
import ChargerCompetitionUltraRapid from '@/assets/map/markers/charger-competition-ultra_rapid.svg?component'

import MarkerNumber1 from '@/assets/map/marker-numbers/1.svg?component'
import MarkerNumber2 from '@/assets/map/marker-numbers/2.svg?component'
import MarkerNumber3 from '@/assets/map/marker-numbers/3.svg?component'
import MarkerNumber4 from '@/assets/map/marker-numbers/4.svg?component'
import MarkerNumber5 from '@/assets/map/marker-numbers/5.svg?component'
import MarkerNumber6 from '@/assets/map/marker-numbers/6.svg?component'
import MarkerNumber7 from '@/assets/map/marker-numbers/7.svg?component'
import MarkerNumber8 from '@/assets/map/marker-numbers/8.svg?component'
import MarkerNumber8Plus from '@/assets/map/marker-numbers/8-plus.svg?component'

import ClusterNumber01 from '@/assets/map/cluster-numbers/Clustering_number-01.svg?component'
import ClusterNumber02 from '@/assets/map/cluster-numbers/Clustering_number-02.svg?component'
import ClusterNumber03 from '@/assets/map/cluster-numbers/Clustering_number-03.svg?component'
import ClusterNumber04 from '@/assets/map/cluster-numbers/Clustering_number-04.svg?component'
import ClusterNumber05 from '@/assets/map/cluster-numbers/Clustering_number-05.svg?component'
import ClusterNumber06 from '@/assets/map/cluster-numbers/Clustering_number-06.svg?component'
import ClusterNumber07 from '@/assets/map/cluster-numbers/Clustering_number-07.svg?component'
import ClusterNumber08 from '@/assets/map/cluster-numbers/Clustering_number-08.svg?component'
import ClusterNumber09 from '@/assets/map/cluster-numbers/Clustering_number-09.svg?component'
import ClusterNumber10 from '@/assets/map/cluster-numbers/Clustering_number-10.svg?component'
import ClusterNumber11 from '@/assets/map/cluster-numbers/Clustering_number-11.svg?component'
import ClusterNumber12 from '@/assets/map/cluster-numbers/Clustering_number-12.svg?component'
import ClusterNumber13 from '@/assets/map/cluster-numbers/Clustering_number-13.svg?component'
import ClusterNumber14 from '@/assets/map/cluster-numbers/Clustering_number-14.svg?component'
import ClusterNumber15 from '@/assets/map/cluster-numbers/Clustering_number-15.svg?component'
import ClusterNumber16 from '@/assets/map/cluster-numbers/Clustering_number-16.svg?component'
import ClusterNumber17 from '@/assets/map/cluster-numbers/Clustering_number-17.svg?component'
import ClusterNumber18 from '@/assets/map/cluster-numbers/Clustering_number-18.svg?component'
import ClusterNumber19 from '@/assets/map/cluster-numbers/Clustering_number-19.svg?component'
import ClusterNumber20 from '@/assets/map/cluster-numbers/Clustering_number-20.svg?component'
import ClusterNumber21 from '@/assets/map/cluster-numbers/Clustering_number-21.svg?component'
import ClusterNumber22 from '@/assets/map/cluster-numbers/Clustering_number-22.svg?component'
import ClusterNumber23 from '@/assets/map/cluster-numbers/Clustering_number-23.svg?component'
import ClusterNumber24 from '@/assets/map/cluster-numbers/Clustering_number-24.svg?component'
import ClusterNumber25 from '@/assets/map/cluster-numbers/Clustering_number-25.svg?component'
import ClusterNumber26 from '@/assets/map/cluster-numbers/Clustering_number-26.svg?component'
import ClusterNumber27 from '@/assets/map/cluster-numbers/Clustering_number-27.svg?component'
import ClusterNumber28 from '@/assets/map/cluster-numbers/Clustering_number-28.svg?component'
import ClusterNumber29 from '@/assets/map/cluster-numbers/Clustering_number-29.svg?component'
import ClusterNumber30 from '@/assets/map/cluster-numbers/Clustering_number-30.svg?component'
import ClusterNumber31 from '@/assets/map/cluster-numbers/Clustering_number-31.svg?component'
import ClusterNumber32 from '@/assets/map/cluster-numbers/Clustering_number-32.svg?component'
import ClusterNumber33 from '@/assets/map/cluster-numbers/Clustering_number-33.svg?component'
import ClusterNumber34 from '@/assets/map/cluster-numbers/Clustering_number-34.svg?component'
import ClusterNumber35 from '@/assets/map/cluster-numbers/Clustering_number-35.svg?component'
import ClusterNumber36 from '@/assets/map/cluster-numbers/Clustering_number-36.svg?component'
import ClusterNumber37 from '@/assets/map/cluster-numbers/Clustering_number-37.svg?component'
import ClusterNumber38 from '@/assets/map/cluster-numbers/Clustering_number-38.svg?component'
import ClusterNumber39 from '@/assets/map/cluster-numbers/Clustering_number-39.svg?component'
import ClusterNumber40 from '@/assets/map/cluster-numbers/Clustering_number-40.svg?component'
import ClusterNumber41 from '@/assets/map/cluster-numbers/Clustering_number-41.svg?component'
import ClusterNumber42 from '@/assets/map/cluster-numbers/Clustering_number-42.svg?component'
import ClusterNumber43 from '@/assets/map/cluster-numbers/Clustering_number-43.svg?component'
import ClusterNumber44 from '@/assets/map/cluster-numbers/Clustering_number-44.svg?component'
import ClusterNumber45 from '@/assets/map/cluster-numbers/Clustering_number-45.svg?component'
import ClusterNumber46 from '@/assets/map/cluster-numbers/Clustering_number-46.svg?component'
import ClusterNumber47 from '@/assets/map/cluster-numbers/Clustering_number-47.svg?component'
import ClusterNumber48 from '@/assets/map/cluster-numbers/Clustering_number-48.svg?component'
import ClusterNumber49 from '@/assets/map/cluster-numbers/Clustering_number-49.svg?component'
import ClusterNumber50 from '@/assets/map/cluster-numbers/Clustering_number-50.svg?component'
import ClusterNumber51 from '@/assets/map/cluster-numbers/Clustering_number-51.svg?component'
import ClusterNumber52 from '@/assets/map/cluster-numbers/Clustering_number-52.svg?component'
import ClusterNumber53 from '@/assets/map/cluster-numbers/Clustering_number-53.svg?component'
import ClusterNumber54 from '@/assets/map/cluster-numbers/Clustering_number-54.svg?component'
import ClusterNumber55 from '@/assets/map/cluster-numbers/Clustering_number-55.svg?component'
import ClusterNumber56 from '@/assets/map/cluster-numbers/Clustering_number-56.svg?component'
import ClusterNumber57 from '@/assets/map/cluster-numbers/Clustering_number-57.svg?component'
import ClusterNumber58 from '@/assets/map/cluster-numbers/Clustering_number-58.svg?component'
import ClusterNumber59 from '@/assets/map/cluster-numbers/Clustering_number-59.svg?component'
import ClusterNumber60 from '@/assets/map/cluster-numbers/Clustering_number-60.svg?component'
import ClusterNumber61 from '@/assets/map/cluster-numbers/Clustering_number-61.svg?component'
import ClusterNumber62 from '@/assets/map/cluster-numbers/Clustering_number-62.svg?component'
import ClusterNumber63 from '@/assets/map/cluster-numbers/Clustering_number-63.svg?component'
import ClusterNumber64 from '@/assets/map/cluster-numbers/Clustering_number-64.svg?component'
import ClusterNumber65 from '@/assets/map/cluster-numbers/Clustering_number-65.svg?component'
import ClusterNumber66 from '@/assets/map/cluster-numbers/Clustering_number-66.svg?component'
import ClusterNumber67 from '@/assets/map/cluster-numbers/Clustering_number-67.svg?component'
import ClusterNumber68 from '@/assets/map/cluster-numbers/Clustering_number-68.svg?component'
import ClusterNumber69 from '@/assets/map/cluster-numbers/Clustering_number-69.svg?component'
import ClusterNumber70 from '@/assets/map/cluster-numbers/Clustering_number-70.svg?component'
import ClusterNumber71 from '@/assets/map/cluster-numbers/Clustering_number-71.svg?component'
import ClusterNumber72 from '@/assets/map/cluster-numbers/Clustering_number-72.svg?component'
import ClusterNumber73 from '@/assets/map/cluster-numbers/Clustering_number-73.svg?component'
import ClusterNumber74 from '@/assets/map/cluster-numbers/Clustering_number-74.svg?component'
import ClusterNumber75 from '@/assets/map/cluster-numbers/Clustering_number-75.svg?component'
import ClusterNumber76 from '@/assets/map/cluster-numbers/Clustering_number-76.svg?component'
import ClusterNumber77 from '@/assets/map/cluster-numbers/Clustering_number-77.svg?component'
import ClusterNumber78 from '@/assets/map/cluster-numbers/Clustering_number-78.svg?component'
import ClusterNumber79 from '@/assets/map/cluster-numbers/Clustering_number-79.svg?component'
import ClusterNumber80 from '@/assets/map/cluster-numbers/Clustering_number-80.svg?component'
import ClusterNumber81 from '@/assets/map/cluster-numbers/Clustering_number-81.svg?component'
import ClusterNumber82 from '@/assets/map/cluster-numbers/Clustering_number-82.svg?component'
import ClusterNumber83 from '@/assets/map/cluster-numbers/Clustering_number-83.svg?component'
import ClusterNumber84 from '@/assets/map/cluster-numbers/Clustering_number-84.svg?component'
import ClusterNumber85 from '@/assets/map/cluster-numbers/Clustering_number-85.svg?component'
import ClusterNumber86 from '@/assets/map/cluster-numbers/Clustering_number-86.svg?component'
import ClusterNumber87 from '@/assets/map/cluster-numbers/Clustering_number-87.svg?component'
import ClusterNumber88 from '@/assets/map/cluster-numbers/Clustering_number-88.svg?component'
import ClusterNumber89 from '@/assets/map/cluster-numbers/Clustering_number-89.svg?component'
import ClusterNumber90 from '@/assets/map/cluster-numbers/Clustering_number-90.svg?component'
import ClusterNumber91 from '@/assets/map/cluster-numbers/Clustering_number-91.svg?component'
import ClusterNumber92 from '@/assets/map/cluster-numbers/Clustering_number-92.svg?component'
import ClusterNumber93 from '@/assets/map/cluster-numbers/Clustering_number-93.svg?component'
import ClusterNumber94 from '@/assets/map/cluster-numbers/Clustering_number-94.svg?component'
import ClusterNumber95 from '@/assets/map/cluster-numbers/Clustering_number-95.svg?component'
import ClusterNumber96 from '@/assets/map/cluster-numbers/Clustering_number-96.svg?component'
import ClusterNumber97 from '@/assets/map/cluster-numbers/Clustering_number-97.svg?component'
import ClusterNumber98 from '@/assets/map/cluster-numbers/Clustering_number-98.svg?component'
import ClusterNumber99 from '@/assets/map/cluster-numbers/Clustering_number-99.svg?component'
import ClusterNumber100 from '@/assets/map/cluster-numbers/Clustering_number-100.svg?component'

import level1Cluster from '@/assets/map/clusters/level1.svg?component'

import ChargerProposedSlow from '@/assets/map/markers/charger-proposed-slow.svg?component'
import ChargerApprovedSlow from '@/assets/map/markers/charger-approved-slow.svg?component'
import ChargerRejectedSlow from '@/assets/map/markers/charger-rejected-slow.svg?component'
import ChargerInReviewSlow from '@/assets/map/markers/charger-review-slow.svg?component'
import ChargerGenerated from '@/assets/map/markers/charger-proposed-generated.svg?component'

import CustomMarker from '@/components/Icon/CustomMarker.vue'


enum SharedComponentTypes {
  ChargerOwnSlow = 'ChargerOwnSlow',
  ChargerOwnFast = 'ChargerOwnFast',
  ChargerOwnRapid = 'ChargerOwnRapid',
  ChargerOwnUltraRapid = 'ChargerOwnUltraRapid',
  ChargerCompetitionSlow = 'ChargerCompetitionSlow',
  ChargerCompetitionFast = 'ChargerCompetitionFast',
  ChargerCompetitionRapid = 'ChargerCompetitionRapid',
  ChargerCompetitionUltraRapid = 'ChargerCompetitionUltraRapid',
  ChargerEconnectSlow = 'ChargerEconnectSlow',
  ChargerEconnectFast = 'ChargerEconnectFast',
  ChargerEconnectRapid = 'ChargerEconnectRapid',
  ChargerEConnectUltraRapid = 'ChargerEconnectUltraRapid',
  MarkerNumber1 = 'MarkerNumber1',
  MarkerNumber2 = 'MarkerNumber2',
  MarkerNumber3 = 'MarkerNumber3',
  MarkerNumber4 = 'MarkerNumber4',
  MarkerNumber5 = 'MarkerNumber5',
  MarkerNumber6 = 'MarkerNumber6',
  MarkerNumber7 = 'MarkerNumber7',
  MarkerNumber8 = 'MarkerNumber8',
  MarkerNumber8Plus = 'MarkerNumber8Plus',
  ChargerProposedSlow = 'ChargerProposedSlow',
  ChargerApprovedSlow= 'ChargerApprovedSlow',
  ChargerRejectedSlow = 'ChargerRejectedSlow',
  ChargerInReviewSlow = 'ChargerInReviewSlow',
  ChargerGenerated = 'ChargerGenerated',
  ClusterNumber01 = 'ClusterNumber01',
  ClusterNumber02 = 'ClusterNumber02',
  ClusterNumber03 = 'ClusterNumber03',
  ClusterNumber04 = 'ClusterNumber04',
  ClusterNumber05 = 'ClusterNumber05',
  ClusterNumber06 = 'ClusterNumber06',
  ClusterNumber07 = 'ClusterNumber07',
  ClusterNumber08 = 'ClusterNumber08',
  ClusterNumber09 = 'ClusterNumber09',
  ClusterNumber10 = 'ClusterNumber10',
  ClusterNumber11 = 'ClusterNumber11',
  ClusterNumber12 = 'ClusterNumber12',
  ClusterNumber13 = 'ClusterNumber13',
  ClusterNumber14 = 'ClusterNumber14',
  ClusterNumber15 = 'ClusterNumber15',
  ClusterNumber16 = 'ClusterNumber16',
  ClusterNumber17 = 'ClusterNumber17',
  ClusterNumber18 = 'ClusterNumber18',
  ClusterNumber19 = 'ClusterNumber19',
  ClusterNumber20 = 'ClusterNumber20',
  ClusterNumber21 = 'ClusterNumber21',
  ClusterNumber22 = 'ClusterNumber22',
  ClusterNumber23 = 'ClusterNumber23',
  ClusterNumber24 = 'ClusterNumber24',
  ClusterNumber25 = 'ClusterNumber25',
  ClusterNumber26 = 'ClusterNumber26',
  ClusterNumber27 = 'ClusterNumber27',
  ClusterNumber28 = 'ClusterNumber28',
  ClusterNumber29 = 'ClusterNumber29',
  ClusterNumber30 = 'ClusterNumber30',
  ClusterNumber31 = 'ClusterNumber31',
  ClusterNumber32 = 'ClusterNumber32',
  ClusterNumber33 = 'ClusterNumber33',
  ClusterNumber34 = 'ClusterNumber34',
  ClusterNumber35 = 'ClusterNumber35',
  ClusterNumber36 = 'ClusterNumber36',
  ClusterNumber37 = 'ClusterNumber37',
  ClusterNumber38 = 'ClusterNumber38',
  ClusterNumber39 = 'ClusterNumber39',
  ClusterNumber40 = 'ClusterNumber40',
  ClusterNumber41 = 'ClusterNumber41',
  ClusterNumber42 = 'ClusterNumber42',
  ClusterNumber43 = 'ClusterNumber43',
  ClusterNumber44 = 'ClusterNumber44',
  ClusterNumber45 = 'ClusterNumber45',
  ClusterNumber46 = 'ClusterNumber46',
  ClusterNumber47 = 'ClusterNumber47',
  ClusterNumber48 = 'ClusterNumber48',
  ClusterNumber49 = 'ClusterNumber49',
  ClusterNumber50 = 'ClusterNumber50',
  ClusterNumber51 = 'ClusterNumber51',
  ClusterNumber52 = 'ClusterNumber52',
  ClusterNumber53 = 'ClusterNumber53',
  ClusterNumber54 = 'ClusterNumber54',
  ClusterNumber55 = 'ClusterNumber55',
  ClusterNumber56 = 'ClusterNumber56',
  ClusterNumber57 = 'ClusterNumber57',
  ClusterNumber58 = 'ClusterNumber58',
  ClusterNumber59 = 'ClusterNumber59',
  ClusterNumber60 = 'ClusterNumber60',
  ClusterNumber61 = 'ClusterNumber61',
  ClusterNumber62 = 'ClusterNumber62',
  ClusterNumber63 = 'ClusterNumber63',
  ClusterNumber64 = 'ClusterNumber64',
  ClusterNumber65 = 'ClusterNumber65',
  ClusterNumber66 = 'ClusterNumber66',
  ClusterNumber67 = 'ClusterNumber67',
  ClusterNumber68 = 'ClusterNumber68',
  ClusterNumber69 = 'ClusterNumber69',
  ClusterNumber70 = 'ClusterNumber70',
  ClusterNumber71 = 'ClusterNumber71',
  ClusterNumber72 = 'ClusterNumber72',
  ClusterNumber73 = 'ClusterNumber73',
  ClusterNumber74 = 'ClusterNumber74',
  ClusterNumber75 = 'ClusterNumber75',
  ClusterNumber76 = 'ClusterNumber76',
  ClusterNumber77 = 'ClusterNumber77',
  ClusterNumber78 = 'ClusterNumber78',
  ClusterNumber79 = 'ClusterNumber79',
  ClusterNumber80 = 'ClusterNumber80',
  ClusterNumber81 = 'ClusterNumber81',
  ClusterNumber82 = 'ClusterNumber82',
  ClusterNumber83 = 'ClusterNumber83',
  ClusterNumber84 = 'ClusterNumber84',
  ClusterNumber85 = 'ClusterNumber85',
  ClusterNumber86 = 'ClusterNumber86',
  ClusterNumber87 = 'ClusterNumber87',
  ClusterNumber88 = 'ClusterNumber88',
  ClusterNumber89 = 'ClusterNumber89',
  ClusterNumber90 = 'ClusterNumber90',
  ClusterNumber91 = 'ClusterNumber91',
  ClusterNumber92 = 'ClusterNumber92',
  ClusterNumber93 = 'ClusterNumber93',
  ClusterNumber94 = 'ClusterNumber94',
  ClusterNumber95 = 'ClusterNumber95',
  ClusterNumber96 = 'ClusterNumber96',
  ClusterNumber97 = 'ClusterNumber97',
  ClusterNumber98 = 'ClusterNumber98',
  ClusterNumber99 = 'ClusterNumber99',
  ClusterNumber100 = 'ClusterNumber100',
  Level1Cluster = 'Level1Cluster'
}

const sharedComponents = [
  { name: SharedComponentTypes.ChargerOwnSlow, component: ChargerOwnSlow },
  { name: SharedComponentTypes.ChargerOwnFast, component: ChargerOwnFast },
  { name: SharedComponentTypes.ChargerOwnRapid, component: ChargerOwnRapid },
  {
    name: SharedComponentTypes.ChargerOwnUltraRapid,
    component: ChargerOwnUltraRapid,
  },
  { name: SharedComponentTypes.ChargerEconnectSlow, component: ChargerEconnectSlow },
  { name: SharedComponentTypes.ChargerEconnectFast, component: ChargerEConnectFast },
  { name: SharedComponentTypes.ChargerEconnectRapid, component: ChargerEConnectRapid },
  { name: SharedComponentTypes.ChargerEConnectUltraRapid, component: ChargerEConnectUltraRapid },

  {
    name: SharedComponentTypes.ChargerCompetitionSlow,
    component: ChargerCompetitionSlow,
  },
  {
    name: SharedComponentTypes.ChargerCompetitionFast,
    component: ChargerCompetitionFast,
  },
  {
    name: SharedComponentTypes.ChargerCompetitionRapid,
    component: ChargerCompetitionRapid,
  },
  {
    name: SharedComponentTypes.ChargerCompetitionUltraRapid,
    component: ChargerCompetitionUltraRapid,
  },
  { name: SharedComponentTypes.MarkerNumber1, component: MarkerNumber1 },
  { name: SharedComponentTypes.MarkerNumber2, component: MarkerNumber2 },
  { name: SharedComponentTypes.MarkerNumber3, component: MarkerNumber3 },
  { name: SharedComponentTypes.MarkerNumber4, component: MarkerNumber4 },
  { name: SharedComponentTypes.MarkerNumber5, component: MarkerNumber5 },
  { name: SharedComponentTypes.MarkerNumber6, component: MarkerNumber6 },
  { name: SharedComponentTypes.MarkerNumber7, component: MarkerNumber7 },
  { name: SharedComponentTypes.MarkerNumber8, component: MarkerNumber8 },
  {
    name: SharedComponentTypes.MarkerNumber8Plus,
    component: MarkerNumber8Plus,
  },
  { name: SharedComponentTypes.ChargerApprovedSlow, component: ChargerApprovedSlow },
  { name: SharedComponentTypes.ChargerRejectedSlow, component: ChargerRejectedSlow },
  { name: SharedComponentTypes.ChargerProposedSlow, component: ChargerProposedSlow },
  { name: SharedComponentTypes.ChargerInReviewSlow, component: ChargerInReviewSlow },
  { name: SharedComponentTypes.ChargerGenerated, component: ChargerGenerated },

  { name: SharedComponentTypes.ClusterNumber01, component: ClusterNumber01 },
  { name: SharedComponentTypes.ClusterNumber02, component: ClusterNumber02 },
  { name: SharedComponentTypes.ClusterNumber03, component: ClusterNumber03 },
  { name: SharedComponentTypes.ClusterNumber04, component: ClusterNumber04 },
  { name: SharedComponentTypes.ClusterNumber05, component: ClusterNumber05 },
  { name: SharedComponentTypes.ClusterNumber06, component: ClusterNumber06 },
  { name: SharedComponentTypes.ClusterNumber07, component: ClusterNumber07 },
  { name: SharedComponentTypes.ClusterNumber08, component: ClusterNumber08 },
  { name: SharedComponentTypes.ClusterNumber09, component: ClusterNumber09 },
  { name: SharedComponentTypes.ClusterNumber10, component: ClusterNumber10 },
  { name: SharedComponentTypes.ClusterNumber11, component: ClusterNumber11 },
  { name: SharedComponentTypes.ClusterNumber12, component: ClusterNumber12 },
  { name: SharedComponentTypes.ClusterNumber13, component: ClusterNumber13 },
  { name: SharedComponentTypes.ClusterNumber14, component: ClusterNumber14 },
  { name: SharedComponentTypes.ClusterNumber15, component: ClusterNumber15 },
  { name: SharedComponentTypes.ClusterNumber16, component: ClusterNumber16 },
  { name: SharedComponentTypes.ClusterNumber17, component: ClusterNumber17 },
  { name: SharedComponentTypes.ClusterNumber18, component: ClusterNumber18 },
  { name: SharedComponentTypes.ClusterNumber19, component: ClusterNumber19 },
  { name: SharedComponentTypes.ClusterNumber20, component: ClusterNumber20 },
  { name: SharedComponentTypes.ClusterNumber21, component: ClusterNumber21 },
  { name: SharedComponentTypes.ClusterNumber22, component: ClusterNumber22 },
  { name: SharedComponentTypes.ClusterNumber23, component: ClusterNumber23 },
  { name: SharedComponentTypes.ClusterNumber24, component: ClusterNumber24 },
  { name: SharedComponentTypes.ClusterNumber25, component: ClusterNumber25 },
  { name: SharedComponentTypes.ClusterNumber26, component: ClusterNumber26 },
  { name: SharedComponentTypes.ClusterNumber27, component: ClusterNumber27 },
  { name: SharedComponentTypes.ClusterNumber28, component: ClusterNumber28 },
  { name: SharedComponentTypes.ClusterNumber29, component: ClusterNumber29 },
  { name: SharedComponentTypes.ClusterNumber30, component: ClusterNumber30 },
  { name: SharedComponentTypes.ClusterNumber31, component: ClusterNumber31 },
  { name: SharedComponentTypes.ClusterNumber32, component: ClusterNumber32 },
  { name: SharedComponentTypes.ClusterNumber33, component: ClusterNumber33 },
  { name: SharedComponentTypes.ClusterNumber34, component: ClusterNumber34 },
  { name: SharedComponentTypes.ClusterNumber35, component: ClusterNumber35 },
  { name: SharedComponentTypes.ClusterNumber36, component: ClusterNumber36 },
  { name: SharedComponentTypes.ClusterNumber37, component: ClusterNumber37 },
  { name: SharedComponentTypes.ClusterNumber38, component: ClusterNumber38 },
  { name: SharedComponentTypes.ClusterNumber39, component: ClusterNumber39 },
  { name: SharedComponentTypes.ClusterNumber40, component: ClusterNumber40 },
  { name: SharedComponentTypes.ClusterNumber41, component: ClusterNumber41 },
  { name: SharedComponentTypes.ClusterNumber42, component: ClusterNumber42 },
  { name: SharedComponentTypes.ClusterNumber43, component: ClusterNumber43 },
  { name: SharedComponentTypes.ClusterNumber44, component: ClusterNumber44 },
  { name: SharedComponentTypes.ClusterNumber45, component: ClusterNumber45 },
  { name: SharedComponentTypes.ClusterNumber46, component: ClusterNumber46 },
  { name: SharedComponentTypes.ClusterNumber47, component: ClusterNumber47 },
  { name: SharedComponentTypes.ClusterNumber48, component: ClusterNumber48 },
  { name: SharedComponentTypes.ClusterNumber49, component: ClusterNumber49 },
  { name: SharedComponentTypes.ClusterNumber50, component: ClusterNumber50 },
  { name: SharedComponentTypes.ClusterNumber51, component: ClusterNumber51 },
  { name: SharedComponentTypes.ClusterNumber52, component: ClusterNumber52 },
  { name: SharedComponentTypes.ClusterNumber53, component: ClusterNumber53 },
  { name: SharedComponentTypes.ClusterNumber54, component: ClusterNumber54 },
  { name: SharedComponentTypes.ClusterNumber55, component: ClusterNumber55 },
  { name: SharedComponentTypes.ClusterNumber56, component: ClusterNumber56 },
  { name: SharedComponentTypes.ClusterNumber57, component: ClusterNumber57 },
  { name: SharedComponentTypes.ClusterNumber58, component: ClusterNumber58 },
  { name: SharedComponentTypes.ClusterNumber59, component: ClusterNumber59 },
  { name: SharedComponentTypes.ClusterNumber60, component: ClusterNumber60 },
  { name: SharedComponentTypes.ClusterNumber61, component: ClusterNumber61 },
  { name: SharedComponentTypes.ClusterNumber62, component: ClusterNumber62 },
  { name: SharedComponentTypes.ClusterNumber63, component: ClusterNumber63 },
  { name: SharedComponentTypes.ClusterNumber64, component: ClusterNumber64 },
  { name: SharedComponentTypes.ClusterNumber65, component: ClusterNumber65 },
  { name: SharedComponentTypes.ClusterNumber66, component: ClusterNumber66 },
  { name: SharedComponentTypes.ClusterNumber67, component: ClusterNumber67 },
  { name: SharedComponentTypes.ClusterNumber68, component: ClusterNumber68 },
  { name: SharedComponentTypes.ClusterNumber69, component: ClusterNumber69 },
  { name: SharedComponentTypes.ClusterNumber70, component: ClusterNumber70 },
  { name: SharedComponentTypes.ClusterNumber71, component: ClusterNumber71 },
  { name: SharedComponentTypes.ClusterNumber72, component: ClusterNumber72 },
  { name: SharedComponentTypes.ClusterNumber73, component: ClusterNumber73 },
  { name: SharedComponentTypes.ClusterNumber74, component: ClusterNumber74 },
  { name: SharedComponentTypes.ClusterNumber75, component: ClusterNumber75 },
  { name: SharedComponentTypes.ClusterNumber76, component: ClusterNumber76 },
  { name: SharedComponentTypes.ClusterNumber77, component: ClusterNumber77 },
  { name: SharedComponentTypes.ClusterNumber78, component: ClusterNumber78 },
  { name: SharedComponentTypes.ClusterNumber79, component: ClusterNumber79 },
  { name: SharedComponentTypes.ClusterNumber80, component: ClusterNumber80 },
  { name: SharedComponentTypes.ClusterNumber81, component: ClusterNumber81 },
  { name: SharedComponentTypes.ClusterNumber82, component: ClusterNumber82 },
  { name: SharedComponentTypes.ClusterNumber83, component: ClusterNumber83 },
  { name: SharedComponentTypes.ClusterNumber84, component: ClusterNumber84 },
  { name: SharedComponentTypes.ClusterNumber85, component: ClusterNumber85 },
  { name: SharedComponentTypes.ClusterNumber86, component: ClusterNumber86 },
  { name: SharedComponentTypes.ClusterNumber87, component: ClusterNumber87 },
  { name: SharedComponentTypes.ClusterNumber88, component: ClusterNumber88 },
  { name: SharedComponentTypes.ClusterNumber89, component: ClusterNumber89 },
  { name: SharedComponentTypes.ClusterNumber90, component: ClusterNumber90 },
  { name: SharedComponentTypes.ClusterNumber91, component: ClusterNumber91 },
  { name: SharedComponentTypes.ClusterNumber92, component: ClusterNumber92 },
  { name: SharedComponentTypes.ClusterNumber93, component: ClusterNumber93 },
  { name: SharedComponentTypes.ClusterNumber94, component: ClusterNumber94 },
  { name: SharedComponentTypes.ClusterNumber95, component: ClusterNumber95 },
  { name: SharedComponentTypes.ClusterNumber96, component: ClusterNumber96 },
  { name: SharedComponentTypes.ClusterNumber97, component: ClusterNumber97 },
  { name: SharedComponentTypes.ClusterNumber98, component: ClusterNumber98 },
  { name: SharedComponentTypes.ClusterNumber99, component: ClusterNumber99 },
  { name: SharedComponentTypes.ClusterNumber100, component: ClusterNumber100 },

  { name: SharedComponentTypes.Level1Cluster, component: level1Cluster},

] as const

const sharedDictionary = sharedComponents.reduce((acc, item) => {
  const root = document.createElement('div')
  const renderedComponent = createApp({
    ...item.component,
  } as any)

  const template = renderedComponent.mount(root).$el.innerHTML

  renderedComponent.unmount()

  acc[item.name] = template

  return acc
}, {} as Record<SharedComponentTypes, string>)

export default sharedDictionary


export const generateMarker = (color: string, label: string) => {
  const root = document.createElement('div')
  const renderedComponent = createApp({
    ...CustomMarker,
  } as any, { color, label })

  const el = renderedComponent.mount(root).$el
  const svgString = new XMLSerializer().serializeToString(el)
  const decoded = unescape(encodeURIComponent(svgString))
  // Now we can use btoa to convert the svg to base64
  const base64 = btoa(decoded)

  renderedComponent.unmount()

  return `data:image/svg+xml;base64,${base64}`
}
// used for cashing the response for reuse in the map feature
const genericMarkerUrlDictionary = (): (c: string, l: string) => string => {
  const markerCache = new Map<string, string>()
 
  return (color: string, label: string): string => {
    const markerKey = `${color}-${label}`
    if (markerCache.has(markerKey)) {
      return markerCache.get(markerKey) as string
    } else {
      const url = generateMarker(color, label)
      markerCache.set(markerKey, url)
      return url 
    }
  }
} 

export const customUploadMarkerIcon = genericMarkerUrlDictionary()
